import React, {Component, Fragment} from 'react';
import firebase from './firebase';
import LoadingSpinner from "./loading-spinner";
import Translate from "react-translate-component";
import attach from "../component/assest/img/attach.svg"
import axios from "axios";

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userFirebase: localStorage.getItem('user_firebase'),
            companyFirebase: localStorage.getItem('company_firebase'),
            messages: '',
            msg: '',
            imageUrl: '',
            file: null,
            loading: true,
        }
    };

    componentDidMount() {
        const ref = firebase.database().ref(this.state.companyFirebase).child('messages');
        ref.on("value", snapshot => {
            this.setState({
                messages: snapshot.val(),
                loading: false,
            });
            this.updateScroll();
        });
    };

    updateScroll = () =>{
        var element = document.getElementById("messagesDiv");
        // this.interval = setInterval(() => {
            element.scrollTop = element.scrollHeight;
        // }, 500);
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    keyPress = (e) =>{
        if(e.keyCode === 13){
            this.updateScroll();
            this.sendMsg();
        }
    };

    toggle = () => {
        let file = document.getElementById('file');
        file.click();
    };

    onChange = (e) => {
        const formData = new FormData();
        formData.append('photo', e.target.files[0]);
        formData.append('api_token', localStorage.getItem("token"));
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        axios.post(window.$urlName + 'uploadPhoto', formData, config)
            .then((response) => {
                this.setState({
                    imageUrl: response.data.photo
                });
                this.sendMsg();
            })
    }

    sendMsg = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const hh = String(today.getHours()).padStart(2, '0'); // => 9
        const m = String(today.getMinutes()).padStart(2, '0'); // =>  30
        const ss = String(today.getSeconds()).padStart(2, '0'); // => 51

        const date = yyyy + '-' + mm + '-' + dd;
        const time = hh + ':' + m + ':' + ss;

        if (this.state.msg !== '' || this.state.imageUrl !== '') {
            // A msg entry.
            const messageData = {
                id: this.state.userFirebase,
                name: localStorage.getItem("name"),
                msg: this.state.msg,
                date: date,
                time: time,
                photo: this.state.imageUrl,
                timeStamp: date + ' ' + time,
            };

            const userData = {
                lastDate: date,
                lastTime: time,
            };

            // Get a key for a new message.
            const newMsgKey = firebase.database().ref().child(this.state.companyFirebase + '/messages').push().key;

            // Write the new message's data simultaneously in the messages list and the user's message list.
            const updates = {};
            updates[this.state.companyFirebase + '/messages/' + newMsgKey] = messageData;
            updates[this.state.companyFirebase + '/Users/' + this.state.userFirebase] = userData;

            let message = this.state.msg;
            if(this.state.imageUrl !==''){
                message = localStorage.getItem("lang") === 'ar' ? "أرسل صورة جديدة" : 'Sent a new photo';
            }

            let values = {
                api_token: localStorage.getItem("token"),
                msg: message,
            };

            axios.post(window.$urlName + 'chat-push', values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then((response) => {

            }).catch(function (error) {
                console.log(error)
            })

            this.setState({
                msg: ''
            });

            if(this.state.msg ===''){
                this.updateScroll();
            }
            return firebase.database().ref().update(updates);
        } else {
            localStorage.getItem("lang") === 'ar' ? alert("من افضلك إدخل رسالتك أولا") : alert('Please Type your message');
        }

    };

    render() {
        const messages = Object.keys(this.state.messages).map((key) => {
            return (
                this.state.messages[key].id !== this.state.userFirebase ?
                    <div key={key} className="outgoing_msg">
                        <div className="sent_msg">
                            <h3 className="sent_head">{this.state.messages[key].name}</h3>
                            <p className="sent_msgParg">{this.state.messages[key].msg}</p>
                            {this.state.messages[key].photo ? <img width="100%" alt="messagePhoto" src={this.state.messages[key].photo}/> : null}
                            <span className="chatDate">{this.state.messages[key].date + ' ' + this.state.messages[key].time}</span>
                        </div>
                    </div>:
                    <div key={key} className="incoming_msg">
                        <div className="incoming_msg_img">
                            <img src={localStorage.getItem("photo")} alt="profile"/>
                        </div>
                        <div className="received_msg">
                            <h3 className="received_head">{this.state.messages[key].name}</h3>
                            <p className="received_msgParg">{this.state.messages[key].msg}</p>
                            {this.state.messages[key].photo ? <img width="100%" alt="messagePhoto" src={this.state.messages[key].photo}/> : null}
                            <span className="chatDate">{this.state.messages[key].date + ' ' + this.state.messages[key].time}</span>
                        </div>
                    </div>
            )
        })
        return (
            <Fragment>
                {this.state.loading ?
                    <div className="loadingMargin">
                        <div className="parent">
                            <div className="child">
                                <LoadingSpinner color={localStorage.getItem("color")} height={'5%'} width={'5%'}/></div>
                        </div>
                    </div> :
                    <div className="messageSide">
                        <div className="messaging" id="messagesDiv">
                            {messages}
                        </div>
                        <div className="type_msg">
                            <div className="input_msg_write">
                                <input type="file" name="photo" onChange={this.onChange} className="inputFile" id="file"/>
                                <Translate attributes={{placeholder: 'typeYourMsg'}} id="msg" className="write_msg" value={this.state.msg} onKeyDown={this.keyPress} onChange={this.handleChange} component="input"/>
                                <button onClick={() => this.toggle()} className="attach_send_btn" type="submit"><img width="25px" alt="attachPhoto" src={attach}/></button>
                                <button onClick={() => this.sendMsg()} className="msg_send_btn" type="submit"><i className="fa fa-location-arrow"></i></button>
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        );
    };
}
export default Chat;