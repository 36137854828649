import React, {Component,Fragment} from 'react';
import logo from "../component/assest/img/logo.svg"
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import axios from "axios";
import {Modal, ModalBody} from "reactstrap";
import LoadingSpinner from "./loading-spinner";
import ops from "../component/assest/img/ops.svg";
import Translate from "react-translate-component";
import {Link} from 'react-router-dom';

class ForgetPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            msgEn:"",
            ErrorModel: false,
            loading: false,
            successMail: false,
        }
    }

    errorMsgModel = () => {
        this.setState(prevState => ({
            ErrorModel: !prevState.ErrorModel,

        }));

    };
    render(){
        return(
            <Fragment>
                <div className="container-fluid signInBody" style={{height:"100vh"}}>
                    <div className="parent">
                        <div className="child">
                            <div className="signInForm">
                                <img src={logo} alt="logo" className="signInLogo"/>
                                <h2><Translate content="hello"/></h2>
                                <p><Translate content="forgetPassword"/></p>
                                {this.state.loading ? <LoadingSpinner height={'10%'} width={'10%'}/> :null}
                                {this.state.successMail ? <p className="successPrg">Success process, please check your email</p> :null}

                                <Formik
                                    initialValues={{
                                        email: '',
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                            .email(<Translate content="invalidEmail"/>)
                                            .required(<Translate content="emailRequired"/>),


                                    })}
                                    onSubmit={values => {
                                        this.setState({
                                            loading : true,
                                            successMail:false
                                        })
                                        axios.post(window.$urlName + 'resetPassword',values, {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Accept': 'application/json'
                                            }

                                        }).then((response) => {
                                            this.setState({
                                                loading : false,
                                                successMail:true
                                            })
                                        }).catch(function(error){
                                            this.setState({
                                                msgEn:<Translate content="invalidEmailPassword"/>,
                                                ErrorModel:true,
                                                loading:false
                                            });
                                        }.bind(this))

                                    }}
                                    >
                                   {({errors, status, touched}) => (
                                        <Form>
                                            <div className="form-group">
                                                <Field name="email" id="email" type="email"
                                                       className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} attributes={{placeholder: 'email'}}/>
                                                <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                            </div>
                                            <button type="submit"><Translate content="reset"/></button>


                                        </Form>
                                    )}
                                </Formik>
                                <div className="loginLink">
                                    <Link to="/"><Translate content="login"/></Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.ErrorModel} toggle={this.errorMsgModel} className="modalBody">
                    <ModalBody>
                        <div className="errorBody">
                            <img src={ops} alt="error"/>
                            <h3>OPSS!</h3>
                            <p>{this.state.msgEn}</p>
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
export default ForgetPassword