import React, {Component, Fragment} from 'react';
import SearchInput, {createFilter} from 'react-search-input'
import axios from "axios";
import {Modal, ModalBody} from "reactstrap";
import {Redirect} from "react-router-dom"

import Translate from "react-translate-component";
import messageIcon from "../component/assest/img/message.png"
import LoadingSpinner from "./loading-spinner";
import imgPlaceholder from "../component/assest/img/img-placeholder.png";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

const KEYS_TO_FILTERS = ['name']

class MyTeam extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: '',
            members: [],
            MsgModel: false,
            loading: true,
            disabled: true,
            success: false,
            memberProfile: {},
            memberTasks: [],
            memberRequests: [],
            memberToken: '',
            redirectProfile: false,
            redirectTasks: false,
            redirectRequests: false
        }
        this.searchUpdated = this.searchUpdated.bind(this)
    }

    componentDidMount() {
        let values = {
            api_token: localStorage.getItem("token")
        };
        axios.post(window.$urlName + 'myTeam', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then((response) => {
            this.setState({
                members: response.data.team,
                loading: false,

            })

        }).catch(function (error) {
            if (error.response.status === 401) {
                window.location.pathname = "/"
            }
            console.log(error)
        })
    }

    sendMsgModel = () => {
        this.setState(prevState => ({
            MsgModel: !prevState.MsgModel,
        }));
        this.setState({
            disabled: true,
            success: false,
        })

    };

    getDetails = (token, type) => {
        if (type === "profile") {
            let values = {
                api_token: token
            }
            axios.post(window.$urlName + 'profile', values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }

            }).then((response) => {
                this.setState({
                    memberProfile: response.data,
                    redirectProfile: true
                })

            })
        } else if (type === "task") {
            this.setState({
                memberToken: token,
                redirectTasks: true
            });
        } else {
            this.setState({
                memberData: token,
                redirectRequests: true
            })
        }

    }

    render() {
        const {redirectProfile, redirectTasks, redirectRequests} = this.state;

        if (redirectProfile) {
            return <Redirect to={{
                pathname: "/member-profile",
                state: {memberProfile: this.state.memberProfile},
            }}
            />
        }
        if (redirectTasks) {
            return <Redirect to={{
                pathname: "/member-tasks",
                state: {memberToken: this.state.memberToken},
            }}
            />
        }
        if (redirectRequests) {
            return <Redirect to={{
                pathname: "/member-requests",
                state: {memberData: this.state.memberData},
            }}
            />
        }
        const {members} = this.state;
        const filteredMembers = members.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
        return (
            <Fragment>
                {this.state.loading ? <div className="loadingMargin">
                        <div className="parent">
                            <div className="child">
                                <LoadingSpinner color={localStorage.getItem("color")} height={'5%'} width={'5%'}/></div>
                        </div>
                    </div> :
                    <div>
                        <div className="container-fluid searchRow">
                            <div className="row">
                                <div className="col-lg-8 col-md-8">
                                    <div className="searchForm">
                                        <SearchInput className="searchInput" onChange={this.searchUpdated}
                                                     placeholder={localStorage.getItem("lang") === 'ar' ? "ابحث عن ..." : "Search For ..."}/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <button disabled={!members.length} type="button" className="messageButt"
                                            onClick={this.sendMsgModel}><img
                                        src={messageIcon} alt="message"/><Translate content="send_message"/></button>
                                </div>
                            </div>
                        </div>
                        <div className="container teamRow">
                            <div className="row">

                                {members.length ? (
                                    filteredMembers.map((member, index) => {
                                        return (
                                            <div key={index} className="col-lg-6 col-md-6">
                                                <div key={index}>
                                                    <div className="teamSingleCard">
                                                        {member.photo === "" ?
                                                            <div className="profileSideImg" style={{
                                                                backgroundImage: "url(" + imgPlaceholder + ")",
                                                            }}></div>
                                                            :
                                                            <div className="profileSideImg" style={{
                                                                backgroundImage: "url(" + member.photo + ")",
                                                            }}></div>
                                                        }
                                                        <div className="introTeamInfo">
                                                            <h3>{member.name}</h3>
                                                            <p>{member.position} | {member.department}</p>
                                                        </div>
                                                    </div>
                                                    <div className="teamCardButtons">

                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4">
                                                                <button type="button" className="teamCardButt"
                                                                        style={{background: "#1A4F87"}}
                                                                        onClick={() => this.getDetails(member.api_token, "profile")}>
                                                                    <Translate content="profile"/></button>
                                                            </div>

                                                            <div className="col-lg-4 col-md-4">
                                                                <button type="button" className="teamCardButt"
                                                                        style={{background: "#FFCD1E"}}
                                                                        onClick={() => this.getDetails(member.api_token, "task")}>
                                                                    <Translate content="task"/></button>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <button type="button" className="teamCardButt"
                                                                        style={{background: "#7AA1F0"}}
                                                                        onClick={() => this.getDetails(member, "requests")}>
                                                                    {member.pendingRequests > 0 ? <div
                                                                        className="requestCircle">{member.pendingRequests}</div> : ""}
                                                                    <Translate content="requests"/></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })


                                ) : (
                                    <Translate content="no_members" component="p" className="emptyObject"/>

                                )}
                            </div>

                        </div>
                    </div>
                }
                <Modal isOpen={this.state.MsgModel} toggle={this.sendMsgModel} className="modalBody">
                    <ModalBody>
                        <div className="msgModalSec">
                            <h3><Translate content="send_msg_for_team"/></h3>
                            <Formik
                                initialValues={{
                                    api_token: localStorage.getItem("token"),
                                    msg: '',
                                }}
                                validationSchema={Yup.object().shape({
                                    msg: Yup.string()
                                        .required(<Translate content="msg_required"/>),
                                })}
                                onSubmit={values => {
                                    axios.post(window.$urlName + 'sendPush', values, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Accept': 'application/json'
                                        }

                                    }).then((response) => {
                                        this.setState({
                                            success: true,
                                            disabled: false
                                        })
                                    }).catch(function (error) {
                                        console.log(error)
                                    })
                                }}
                            >

                                {({errors, status, touched}) => (
                                    <Form>
                                        {this.state.success ?
                                            <Translate className="successPrg" content="msgSend" component="p"/> : null}
                                        <div className="form-group">
                                            <Field name="msg" id="msg" type="text" component="textarea"
                                                   className={'form-control' + (errors.msg && touched.msg ? ' is-invalid' : '')}
                                                   placeholder={localStorage.getItem("lang") === "ar" ? "إكتب رسالتك" : "Write your message"}/>
                                            <ErrorMessage name="msg" component="div" className="invalid-feedback"/>
                                        </div>

                                        <button type="submit" disabled={!this.state.disabled}><Translate
                                            content="send"/></button>


                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }

    searchUpdated(term) {
        this.setState({searchTerm: term})
    }
}

export default MyTeam