import React, {Component, Fragment} from 'react';
import {ProgressBar} from "react-bootstrap"
import axios from "axios";
import Translate from "react-translate-component";
import LoadingSpinner from "./loading-spinner";

class CheckIn extends Component{
    constructor(props){
        super(props)
        this.state = {
            hoursMax:0,
            dayHoursMax:0,
            hoursEmployee:0,
            currentProgress:0,
            periods:[],
            loading:true,
            showSession:false,
        }
    }

    componentDidMount() {
        let values = {
            api_token: localStorage.getItem("token")
        };
        axios.post(window.$urlName + 'checkInHistory', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then((response) => {
            let progress = 0
            if(response.data.hoursMax !== 0){
                progress = (response.data.hoursEmployee / parseInt(response.data.hoursMax)) * 100;
            }
            this.setState({
                hoursMax:parseInt(response.data.hoursMax),
                dayHoursMax:response.data.dayhoursMax,
                hoursEmployee:response.data.hoursEmployee,
                currentProgress:progress,
                periods:response.data.periods,
                loading:false,
            })


        })
    }

    showSessions =() => {
        this.setState(prevState => ({
            showSession: !prevState.showSession,
        }));
    }

    getSessions = (sessions) => {

        return(
            sessions.map((session,index)=>{
                return(
                    <div key={index} className="sessionBody">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className="rangeTime"><Translate content="from" component="p"/><span>{session.from}</span></div>
                            <div className="rangeTime"><Translate content="to" component="p"/><span>{session.to}</span></div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <p className="totalTime">{session.total}</p>
                        </div>
                    </div>
                    </div>
                )
            })
        )
    }
    render(){

            let periods = this.state.periods.reverse().map((period,index)=>{
                return(
                    <div key={index}>
                    <div className="checkInSec">
                        <div className="row">
                            <div className="col-lg-3 col-md-3">
                                <h3>{period.period}</h3>
                            </div>
                            <div className="col-lg-9 col-md-9">
                                <div className="progressSec">
                                    <ProgressBar striped now={(period.hours/this.state.dayHoursMax)*100} label={`${parseInt((period.hours/this.state.dayHoursMax)*100)}%`}/>
                                    {this.getSessions(period.sessions)}
                                    {/*<button type="button" onClick={this.showSessions} className="sessionButton"><Translate content="see_sessions"/></button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    </div>
                )
            })
        return(
            <Fragment>
                {this.state.loading ? <div className="loadingMargin">
                        <div className="parent">
                            <div className="child">
                                <LoadingSpinner color={localStorage.getItem("color")} height={'5%'} width={'5%'}/></div>
                        </div>
                    </div> :
                    <div className="container">

                        <div className="currentProgress">
                            <div className="row">
                            <div className="col-lg-3 col-md-3">
                                <Translate content="currentProgress" component="h3"/>
                            </div>
                            <div className="col-lg-9 col-md-9">
                                <ProgressBar striped now={this.state.currentProgress} label={`${parseInt(this.state.currentProgress)}%`}/>

                            </div>
                            </div>
                        </div>
                        <hr/>

                        {periods}

                    </div>
                }
            </Fragment>
        )
    }
}
export default CheckIn