import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Accordion, Card, AccordionToggle, AccordionCollapse, Button} from "react-bootstrap"
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import 'react-calendar/dist/Calendar.css';
import Translate from "react-translate-component";
import toggleRow from "../component/assest/img/toggleRow.svg"
import backlog from "../component/assest/img/backlog.svg"
import inProgressImg from "../component/assest/img/inProgress.svg"
import done from "../component/assest/img/done.svg"
import {ProgressBar} from "react-bootstrap";
import axios from "axios";
import LoadingSpinner from "./loading-spinner";
import imgPlaceholder from "../component/assest/img/img-placeholder.png";
import Calendar from 'react-calendar';

class MemberTasks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            memberToken : this.props.location.state.memberToken,
            dataArr:[],
            tasksArr: [],
            backlogArr: [],
            inProgressArr: [],
            doneArr: [],
            backlogNum: 0,
            inProgressNum: 0,
            doneNum: 0,
            tasksNum: 0,
            progressPercentage:0,
            date: new Date(),
            loading: true,
        }
    }

    componentDidMount() {
        let values = {
            api_token: this.state.memberToken
        };
        axios.post(window.$urlName + 'get-tasks', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then((response) => {
            let {dataArr,tasksArr,backlogArr, inProgressArr, doneArr,tasksNum, backlogNum, inProgressNum, doneNum} = this.state;
            let dates = response.data.data.dates;
            for (const date of dates) {
                dataArr.push(date)
                for (const task of date.tasks) {
                    tasksArr.push(task)
                    tasksNum +=1
                    if (task.status === "backlog") {
                        backlogArr.push(task);
                        backlogNum += 1
                    } else if (task.status === "in-porgress") {
                        inProgressArr.push(task);
                        inProgressNum += 1
                    } else {
                        doneArr.push(task);
                        doneNum += 1
                    }
                }
            }
            this.setState({
                loading:false,
                dataArr:dataArr,
                tasksArr: tasksArr,
                backlogArr: backlogArr,
                inProgressArr: inProgressArr,
                doneArr: doneArr,
                backlogNum: backlogNum,
                inProgressNum: inProgressNum,
                doneNum: doneNum,
                tasksNum: tasksNum,
                progressPercentage: (tasksNum > 0) ? ((doneNum/tasksNum)*100).toFixed(1) : 0,
            })
        }).catch(function (error) {
            if (error.response.status === 401) {
                window.location.pathname = "/"
            }
            console.log(error)
        })
    }

    getEmployees = (employees) => {
        return (
            employees.map((employee, index) => {
                return (
                    <div className="profileSwipe" key={index}>
                        {employee.image === "" ?
                            <div className="profileSideImg" style={{
                                backgroundImage: "url(" + imgPlaceholder + ")",
                            }}></div>
                            :
                            <div className="profileSideImg" style={{
                                backgroundImage: "url(" + employee.image + ")",
                            }}></div>
                        }
                        <p>{employee.name}</p>
                    </div>
                )
            })
        )
    }

    onChangeCalender = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = date.getFullYear();
        let x = yyyy+'-'+mm+'-'+dd;
        let obj = { value: x, label: x}
        this.handleChange(obj);
        this.setState({ date })
    };

    handleChange = (selectedOption) => {
        let {dataArr,tasksArr,backlogArr, inProgressArr, doneArr,tasksNum, backlogNum, inProgressNum, doneNum} = this.state;
        this.setState({ selectedOption });
        let index = dataArr.findIndex(m => m.date === selectedOption.value);
        if(index !== -1){
            let newTasksArr = dataArr[index].tasks;
            for (const task of newTasksArr) {
                tasksArr.push(task)
                tasksNum +=1
                if (task.status === "backlog") {
                    backlogArr.push(task);
                    backlogNum += 1
                } else if (task.status === "in-porgress") {
                    inProgressArr.push(task);
                    inProgressNum += 1
                } else {
                    doneArr.push(task);
                    doneNum += 1
                }
            }
            this.setState({
                loading:false,
                tasksArr: tasksArr,
                backlogArr: backlogArr,
                inProgressArr: inProgressArr,
                doneArr: doneArr,
                backlogNum: backlogNum,
                inProgressNum: inProgressNum,
                doneNum: doneNum,
                tasksNum: tasksNum,
                progressPercentage: (tasksNum > 0) ? ((doneNum/tasksNum)*100).toFixed(1) : 0,
            })
        }else{
            this.setState({
                loading:false,
                tasksArr: [],
                backlogArr: [],
                inProgressArr: [],
                doneArr: [],
                backlogNum: 0,
                inProgressNum: 0,
                doneNum: 0,
                tasksNum: 0,
                progressPercentage: 0,
            })
        }


    };

    render() {
        const params = {
            slidesPerView: 3,
            shouldSwiperUpdate: true,
            rebuildOnUpdate: true,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 3,
                },
                640: {
                    slidesPerView: 2,
                },
                320: {
                    slidesPerView: 1,
                }
            },
            // autoplay: {
            //     delay: 3000,
            // color
            // },
        };

        let backlogArr = this.state.backlogArr.length ? (
                this.state.backlogArr.map((task, index) => {
                    return (
                        <Card key={index} style={{borderLeft: "10px solid " + task.color}}>
                            <Card.Header>
                                <AccordionToggle as={Button} variant="link" eventKey={index}>
                                    <div className="toggleHead">
                                        <h3>{task.project_name} <Link
                                            to={{pathname: `/edit-task/${task.id}`, state: {task: task}}}
                                            className="editIcon"><i className="fa fa-edit"></i></Link></h3>
                                        <p>{task.title}</p>
                                        <p style={{color: " " + task.color + ""}}>{task.deadline}</p>
                                        <img src={toggleRow} alt="arrow"/>
                                    </div>
                                </AccordionToggle>
                            </Card.Header>
                            <AccordionCollapse eventKey={index}>
                                <Card.Body>
                                    <div className="accBody">
                                        <p>{task.description}</p>
                                        {task.employees.length ? <Swiper {...params}>
                                            {this.getEmployees(task.employees)}
                                        </Swiper> : null}
                                        <hr/>
                                        <div className="changeStateSec">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Translate content="change_state"
                                                               component="h3"/>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="backlogButton">
                                                        <button id="backlog" disabled={task.status === "backlog"}
                                                                className={`${task.status === "backlog" ? "activeState" : ""}`}><img
                                                            src={backlog} alt="backlog"/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="inProgressButton">
                                                        <button id="in-porgress" disabled={task.status === "in-porgress"}
                                                                className={`${task.status === "in-porgress" ? "activeState" : ""}`}>
                                                            <img src={inProgressImg}
                                                                 alt="inProgress"/></button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="DoneButton">
                                                        <button id="done" disabled={task.status === "done"}
                                                                className={`${task.status === "done" ? "activeState" : ""}`}><img
                                                            src={done} alt="done"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </AccordionCollapse>
                        </Card>
                    )
                })

            ) :

            (
                <Translate content="no_tasks" component="p" className="emptyObject"/>

            );

        let inProgressArr = this.state.inProgressArr.length ? (
                this.state.inProgressArr.map((task, index) => {
                    return (
                        <Card key={index} style={{borderLeft: "10px solid " + task.color}}>
                            <Card.Header>
                                <AccordionToggle as={Button} variant="link" eventKey={index}>
                                    <div className="toggleHead">
                                        <h3>{task.project_name} <Link
                                            to={{pathname: `/edit-task/${task.id}`, state: {task: task}}}
                                            className="editIcon"><i className="fa fa-edit"></i></Link></h3>
                                        <p>{task.title}</p>
                                        <p style={{color: " " + task.color + ""}}>{task.deadline}</p>

                                        <img src={toggleRow} alt="arrow"/>
                                    </div>
                                </AccordionToggle>
                            </Card.Header>
                            <AccordionCollapse eventKey={index}>
                                <Card.Body>
                                    <div className="accBody">
                                        <p>{task.description}</p>
                                        {task.employees.length ? <Swiper {...params}>
                                            {this.getEmployees(task.employees)}
                                        </Swiper> : null}
                                        <hr/>
                                        <div className="changeStateSec">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Translate content="change_state"
                                                               component="h3"/>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="backlogButton">
                                                        <button id="backlog" disabled={task.status === "backlog"}
                                                                className={`${task.status === "backlog" ? "activeState" : ""}`}><img
                                                            src={backlog} alt="backlog"/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="inProgressButton">
                                                        <button id="in-porgress" disabled={task.status === "in-porgress"}
                                                                className={`${task.status === "in-porgress" ? "activeState" : ""}`}>
                                                            <img src={inProgressImg}
                                                                 alt="inProgress"/></button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="DoneButton">
                                                        <button id="done" disabled={task.status === "done"}
                                                                className={`${task.status === "done" ? "activeState" : ""}`}><img
                                                            src={done} alt="done"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </AccordionCollapse>
                        </Card>
                    )
                })

            ) :

            (
                <Translate content="no_tasks" component="p" className="emptyObject"/>

            );

        let doneArr = this.state.doneArr.length ? (
                this.state.doneArr.map((task, index) => {
                    return (
                        <Card key={index} style={{borderLeft: "10px solid " + task.color}}>
                            <Card.Header>
                                <AccordionToggle as={Button} variant="link" eventKey={index}>
                                    <div className="toggleHead">
                                        <h3>{task.project_name} <Link
                                            to={{pathname: `/edit-task/${task.id}`, state: {task: task}}}
                                            className="editIcon"><i className="fa fa-edit"></i></Link></h3>
                                        <p>{task.title}</p>
                                        <p style={{color: " " + task.color + ""}}>{task.deadline}</p>
                                        <img src={toggleRow} alt="arrow"/>
                                    </div>
                                </AccordionToggle>
                            </Card.Header>
                            <AccordionCollapse eventKey={index}>
                                <Card.Body>
                                    <div className="accBody">
                                        <p>{task.description}</p>
                                        {task.employees.length ? <Swiper {...params}>
                                            {this.getEmployees(task.employees)}
                                        </Swiper> : null}
                                        <hr/>
                                        <div className="changeStateSec">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Translate content="change_state"
                                                               component="h3"/>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="backlogButton">
                                                        <button id="backlog" disabled={task.status === "backlog"}
                                                                className={`${task.status === "backlog" ? "activeState" : ""}`}><img
                                                            src={backlog} alt="backlog"/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="inProgressButton">
                                                        <button id="in-porgress" disabled={task.status === "in-porgress"}
                                                                className={`${task.status === "in-porgress" ? "activeState" : ""}`}>
                                                            <img src={inProgressImg}
                                                                 alt="inProgress"/></button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="DoneButton">
                                                        <button id="done" disabled={task.status === "done"}
                                                                className={`${task.status === "done" ? "activeState" : ""}`}><img
                                                            src={done} alt="done"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </AccordionCollapse>
                        </Card>
                    )
                })

            ) :

            (
                <Translate content="no_tasks" component="p" className="emptyObject"/>

            );

        let tasks = this.state.tasksArr.length ? (
                this.state.tasksArr.map((task, index) => {
                    return (
                        <Card key={index} style={{borderLeft: "10px solid " + task.color}}>
                            <Card.Header>
                                <AccordionToggle as={Button} variant="link" eventKey={index}>
                                    <div className="toggleHead">
                                        <h3>{task.project_name} <Link
                                            to={{pathname: `/edit-task/${task.id}`, state: {task: task}}}
                                            className="editIcon"><i className="fa fa-edit"></i></Link></h3>
                                        <p>{task.title}</p>
                                        <p style={{color: " " + task.color + ""}}>{task.deadline}</p>
                                        <img src={toggleRow} alt="arrow"/>
                                    </div>
                                </AccordionToggle>
                            </Card.Header>
                            <AccordionCollapse eventKey={index}>
                                <Card.Body>
                                    <div className="accBody">
                                        <p>{task.description}</p>
                                        {task.employees.length ?
                                            <Swiper {...params}>
                                                {this.getEmployees(task.employees)}
                                            </Swiper>
                                            : null}
                                        <hr/>
                                        <div className="changeStateSec">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <Translate content="change_state"
                                                               component="h3"/>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="backlogButton">
                                                        <button id="backlog" disabled={task.status === "backlog"}
                                                                className={`${task.status === "backlog" ? "activeState" : ""}`}><img
                                                            src={backlog} alt="backlog"/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="inProgressButton">
                                                        <button id="in-porgress" disabled={task.status === "in-porgress"}
                                                                className={`${task.status === "in-porgress" ? "activeState" : ""}`}>
                                                            <img src={inProgressImg}
                                                                 alt="inProgress"/></button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="DoneButton">
                                                        <button id="done" disabled={task.status === "done"}
                                                                className={`${task.status === "done" ? "activeState" : ""}`}><img
                                                            src={done} alt="done"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Card.Body>
                            </AccordionCollapse>
                        </Card>
                    )
                })) :

            (
                <Translate content="no_tasks" component="p" className="emptyObject"/>

            );
        return (
            <Fragment>
                {this.state.loading ? <div className="loadingMargin">
                        <div className="parent">
                            <div className="child">
                                <LoadingSpinner color={localStorage.getItem("color")} height={'5%'} width={'5%'}/></div>
                        </div>
                    </div> :
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <Tabs>
                                    <div className="tabList">
                                        <div className="tabsBody">

                                            <div className="row">
                                                <div className="col-lg-8 col-md-8">
                                                    <TabList>
                                                        <Tab><Translate content="all"/></Tab>
                                                        <Tab><Translate content="back_log"/></Tab>
                                                        <Tab><Translate content="in_progress"/></Tab>
                                                        <Tab><Translate content="done"/></Tab>

                                                    </TabList>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <TabPanel>
                                        <Accordion defaultActiveKey={0}>
                                            {tasks}
                                        </Accordion>

                                    </TabPanel>
                                    <TabPanel>
                                        <Accordion defaultActiveKey={0}>
                                            {backlogArr}
                                        </Accordion>
                                    </TabPanel>
                                    <TabPanel>
                                        <Accordion defaultActiveKey={0}>
                                            {inProgressArr}
                                        </Accordion>
                                    </TabPanel>
                                    <TabPanel>
                                        <Accordion defaultActiveKey={0}>
                                            {doneArr}
                                        </Accordion>
                                    </TabPanel>

                                </Tabs>
                            </div>
                            <div className="col-lg-4 col-md-4 rightSide">
                                <div className="taskRightSide">
                                    <Calendar
                                        onChange={this.onChangeCalender}
                                        value={this.state.date}
                                    />
                                    <hr/>
                                    <div className="taskProgress">
                                        <Translate content="progress" component="p"/>
                                        <ProgressBar striped now={this.state.progressPercentage} label={`${this.state.progressPercentage}%`} />

                                    </div>
                                    <hr/>
                                    <div className="historyProgress">
                                        <Translate content="history" component="p"/>
                                        <div><img src={done} alt="done"/><span style={{color:"#18D610"}}>{this.state.doneNum}</span><Translate content="projects_done"/></div>
                                        <div><img src={inProgressImg} alt="inProgress"/><span style={{color:"#FFA439"}}>{this.state.inProgressNum}</span><Translate content="projects_inProgress"/></div>
                                        <div><img src={backlog} alt="backlog"/><span style={{color:"#FF2626"}}>{this.state.backlogNum}</span><Translate content="projects_backlog"/></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

export default MemberTasks