import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";

class MemberProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userInfo: this.props.location.state.memberProfile,
        }
    }

    render() {
        return (
            <Fragment>
                <div className="memberTable">
                    <div className="personalIntro">
                        <div className="profileSideImg" style={{
                            backgroundImage: "url(" + this.state.userInfo.photo + ")",
                        }}></div>

                        <div className="personalIntroInfo">
                            <h3>{this.state.userInfo.name}</h3>
                            <p>{this.state.userInfo.position}</p>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <tbody>
                            <tr>
                                <Translate content="department" component="th"/>
                                <td>{this.state.userInfo.department}</td>
                            </tr>
                            <tr>
                                <Translate content="phone" component="th"/>
                                <td>{this.state.userInfo.phone}</td>
                            </tr>
                            <tr>
                                <Translate content="email" component="th"/>
                                <td>{this.state.userInfo.email}</td>
                            </tr>
                            <tr>
                                <Translate content="hiring_date" component="th"/>
                                <td>{this.state.userInfo.join_date}</td>
                            </tr>
                            <tr>
                                <Translate content="birth_date" component="th"/>
                                <td>{this.state.userInfo.birthdate}</td>
                            </tr>
                            <tr>
                                <Translate content="salary" component="th"/>
                                <td>{this.state.userInfo.salary}</td>
                            </tr>
                            <tr>
                                <Translate content="normal_vacation" component="th"/>
                                <td>{this.state.userInfo.normalVacations}</td>
                            </tr>
                            <tr>
                                <Translate content="casual_vacation" component="th"/>
                                <td>{this.state.userInfo.casualVacations}</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default MemberProfile