import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import 'firebase/messaging';  // If using Firebase messaging

    const config = {
        apiKey: "AIzaSyDaE2a23n7Vtv-Xs8ytt-jvbEwKGhecaS8",
        authDomain: "bdaiat-hr.firebaseapp.com",
        databaseURL: "https://bdaiat-hr.firebaseio.com",
        projectId: "bdaiat-hr",
        storageBucket: "bdaiat-hr.appspot.com",
        messagingSenderId: "1022590909449",
        appId: "1:1022590909449:web:9fe081ef9e4bcd50d1ccd8"
    };

    // Initialize Firebase
    firebase.initializeApp(config);

    export const askForPermissioToReceiveNotifications = async () => {
        try {
            const messaging = firebase.messaging();
            await messaging.requestPermission();
            const token = await messaging.getToken();
            if (localStorage.getItem('pushToken') !== token) {
                localStorage.setItem('pushToken', token);
            }
            return token;
        } catch (error) {
            console.error(error);
        }
    }

export default firebase;