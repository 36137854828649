import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Accordion, Card, AccordionToggle, AccordionCollapse, Button} from "react-bootstrap"
import Select from 'react-select';
import Translate from "react-translate-component";
import plus from "../component/assest/img/pluse.png";
import toggleRow from "../component/assest/img/toggleRow.svg"
import axios from "axios";
import LoadingSpinner from "./loading-spinner";
import imgPlaceholder from "../component/assest/img/img-placeholder.png";
import Calendar from "react-calendar";

class MyMeetings extends Component{
    constructor(props) {
        super(props)
        this.state = {
            dataArr:[],
            datesArr:[],
            meetingsArr:[],
            selectedOption: null,
            meetingNum: 0,
            date: new Date(),
            loading:true
        }
    }

    componentDidMount() {
        let values = {
            api_token: localStorage.getItem("token")
        };
        axios.post(window.$urlName + 'get-meetings', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
           let {datesArr,dataArr,meetingsArr} =this.state;
            let dates = response.data.data.dates;
            let numbers = 0;
            datesArr.push({ value: "All", label: "All"})
            for (const date of dates) {
                 let obj = { value: date.date, label: date.date}
                datesArr.push(obj)
                dataArr.push(date)
                for (const meeting of date.meetings) {
                    meetingsArr.push(meeting)
                    numbers +=1
                }
            }
            this.setState({
                loading:false,
                dataArr:dataArr,
                meetingsArr:meetingsArr,
                meetingNum:numbers
            })
        }).catch(function (error) {
            if (error.response.status === 401){
               window.location.pathname="/"
            }
            console.log(error)
        })
    }

    onChangeCalender = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = date.getFullYear();
        let x = yyyy+'-'+mm+'-'+dd;
        let obj = { value: x, label: x}
        this.handleChange(obj);
        this.setState({ date })
    };

    handleChange = (selectedOption) => {
        let {dataArr} = this.state
        this.setState({ selectedOption });
        if(selectedOption.value !== "All"){
            let index = dataArr.findIndex(m => m.date === selectedOption.value);
            let newMeetingsArr = [];
            let numbers = 0;
            if(index !== -1){
                let meetingsArr = dataArr[index].meetings;
                for (const meeting of meetingsArr) {
                    newMeetingsArr.push(meeting);
                    numbers +=1;
                }
            }

            this.setState({
                meetingsArr : newMeetingsArr,
                meetingNum:numbers
            })
        }else {
            let numbers = 0;
            let newMeetingsArr = [];
            let dates = this.state.dataArr;
            for (const date of dates) {
                for (const meeting of date.meetings) {
                    newMeetingsArr.push(meeting)
                    numbers +=1
                }
            }
            this.setState({
                meetingsArr : newMeetingsArr,
                meetingNum : numbers
            })
        }
    };

    getEmployees = (employees) => {
        return(
            employees.map((employee,index) => {
                return(
                    employee.image === "" ?
                        <div className="profileSideImg" key={index} style={{
                            right: index * 30,
                            backgroundImage: "url(" + imgPlaceholder + ")",
                        }}></div>
                        :
                        <div className="profileSideImg" key={index} style={{
                            right: index * 30,
                            backgroundImage: "url(" + employee.image + ")",
                        }}></div>
                )
            })
        )
    };

    render(){
        let sideMeetings = this.state.meetingsArr.length ? (
                this.state.meetingsArr.map((meeting,index) => {
                    return (
                        <div key={index}>
                            <div className="row">
                                <div className="col-lg-5 col-md-5">
                                    <h3>{meeting.date}</h3>
                                </div>
                                <div className="col-lg-7 col-md-7">
                                    <h4>{meeting.title}</h4>
                                </div>
                            </div>
                        </div>
                    )
                })
            ):

            (
                <Translate content="no_meetings" component="p" className="emptyObject"/>

            );

        let meetings = this.state.meetingsArr.length ? (
            this.state.meetingsArr.map((meeting,index) => {

            return (
                <Card key={index}>
                    <Card.Header>
                        <AccordionToggle as={Button} variant="link" eventKey={index+1}>
                            <div className="toggleHead">
                                <h3>{meeting.title} {meeting.allowEdit ? <Link to={{pathname: `/edit-meeting/${meeting.id}`,state:{meeting:meeting}}} className="editIcon"><i className="fa fa-edit"></i></Link> :null}</h3>
                                <p>{meeting.date} | {meeting.time_from} - {meeting.time_to}</p>
                                <img src={toggleRow} alt="arrow"/>
                            </div>
                        </AccordionToggle>
                    </Card.Header>
                    <AccordionCollapse eventKey={index+1}>
                        <Card.Body>
                            <div className="accBody">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <p>{meeting.description}</p>

                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="profileSwipe">
                                        {meeting.employees.length ?
                                            this.getEmployees(meeting.employees)
                                        :null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </AccordionCollapse>
                </Card>
            )
        })
            ):

        (
            <Translate content="no_meetings" component="p" className="emptyObject"/>

        );

        return(
            <Fragment>
                {this.state.loading ? <div className="loadingMargin">
                        <div className="parent">
                            <div className="child">
                                <LoadingSpinner color={localStorage.getItem("color")} height={'5%'} width={'5%'}/></div>
                        </div>
                    </div> :
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className="addMeetingRow">

                            <div className="row">
                                <div className="col-lg-7 col-md-7">
                                    <Select
                                        value={this.state.selectedOption}
                                        onChange={this.handleChange}
                                        options={this.state.datesArr}
                                        placeholder={localStorage.getItem("lang")==="ar"?"تاريخ ...":"Date by ..."}
                                    />
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <Link className="addRequestButton" to="/add-meeting"><Translate
                                        content="add_new_meeting"/><img src={plus} alt="add"/></Link>
                                </div>
                            </div>
                                {this.state.meetingNum >0 ?<p><Translate content="youHave"/> {this.state.meetingNum} <Translate
                                    content="meeting"/> </p> : ""}
                                <hr/>
                            </div>
                                    <Accordion defaultActiveKey={0}>
                                        {meetings}

                            </Accordion>

                        </div>
                        <div className="col-lg-4 col-md-4 rightSide">
                            <div className="taskRightSide">
                                <Calendar
                                    onChange={this.onChangeCalender}
                                    value={this.state.date}
                                />
                                <hr/>
                                <div className="meetingHistory">
                                    <Translate content="history" component="p"/>
                                    {sideMeetings}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </Fragment>
        )
    }
}
export default MyMeetings