export default {

    'lang': 'English',
    'welcome': 'مرحباً',
    'home': 'الرئيسية',
    'profile': 'الصفحة الشخصية',
    'check-in-history': 'سجل الحضور',
    'my_team': 'فريقي',
    'requests': 'الطلبات',
    'my_tasks': 'المهام',
    'public_vacations': 'العطلات الرسمية',
    'meetings': 'الإجتماعات',
    'help': 'المساعدة',
    'check_in': 'تسجيل الدخول',
    'check_out': 'تسجيل خروج',
    'company': 'الشركة',
    'hiring_date': 'تاريخ التعيين',
    'birth_date': 'تاريخ الميلاد',
    'salary': 'المرتب',
    'normal_vacation': 'الأجازات الأعتيادية',
    'casual_vacation': 'الأجازات العارضة',
    'salary_sheet': 'بيان المرتب',
    'log_out': 'تسجبل خروج',
    'edit': 'تعديل',
    'full_salary': 'الراتب الكامل',
    'work_day': 'ايام العمل',
    'absent': 'غائب',
    'advance': 'زيادات',
    'deduction': 'خصم',
    'net_salary': 'صافى الراتب',
    'search': 'بحث',
    'send_message': 'إرسال رسالة',
    'summary': 'ملخص',
    'task': 'المهام',
    'send_msg_for_team': 'إرسل رسالة لفريقك',
    'send': 'إرسال',
    'vacation_name': 'العطلة',
    'vacation_date': 'تاريخ الأجازة',
    'contact_us': 'تواصل معنا',
    'chat_us': 'تواصل معنا',
    'new_feature': 'إضافة جديدة',
    'complain': 'شكوي',
    'your_comment_about': 'تعليقك عن',
    'vacations': 'الأجازات',
    'complains': 'الشكاوي',
    'leaving': 'الأذونات',
    'accept': 'قبول',
    'reject': 'رفض',
    'approved': 'تم الموافقة',
    'rejected': 'تم الرفض',
    'date': 'تاريخ',
    'subject': 'الرسالة',
    'state': 'الحالة',
    'add_new_request': 'أضف طلب',
    'vacation_type': 'النوع',
    'choose_type': 'إختر نوع',
    'date_from': 'من',
    'date_to': 'إلي',
    'request_details': 'تفاصيل الطلب',
    'vacation_type_required': 'نوع الاجازة مطلوب',
    'date_required': 'التاريخ مطلوب',
    'details_required': 'التفاصيل مطلوبة',
    'back_log': 'غير منفذة',
    'in_progress': 'قيد التنفيذ',
    'done': 'منفذة',
    'change_state': 'تغيير الحالة',
    'progress': 'تقدم',
    'projects_done': 'منفذة',
    'projects_inProgress': 'قيد التنفيذ',
    'projects_backlog': 'غير منفذة',
    'history': 'الأرشيف',
    'add_new_task': 'إضافة مهمة جديدة',
    'title': 'العنوان',
    'project': 'المشروع',
    'dateLine': 'الوقت المحدد',
    'colorCode': 'اللون',
    'title_required': 'العنوان مطلوب',
    'project_required': 'اسم المشروع مطلوب',
    'dateLine_required': 'الوقت المحدد مطلوب',
    'color_required': 'اللون مطلوب',
    'about_required': 'عن المشروع مطلوب',
    'save_task': 'حفط المهمة',
    'choose_color': 'إختر اللون',
    'addTaskTo': 'رفق المهمة إلي',
    'add_new_meeting': 'إضافة إجتماع جديد',
    'time_from': 'الوقت من',
    'time_to': 'الوقت إلي',
    'invite_people': 'دعوة الزملاء',
    'save_meeting': 'حفظ الإجتماع',
    'time_required': 'الوقت مطلوب',
    'description_required': 'الوصف مطلوب',
    'from': 'من',
    'to': 'إلي',
    'upload': 'حفظ',
    'phone': 'الهاتف',
    'name': 'الإسم',
    'email': 'البريد الإلكتروني',
    'password': 'كلمة المرور',
    'save': 'حفظ',
    'back': 'رجوع',
    'name_validation': 'يجب ألا يقل الإسم عن 4 احرف',
    'phone_validation': 'خطأ في رقم الهاتف',
    'email_validation': 'خطأ في الإيميل',
    'password_validation': 'يجب ألا تقل كلمة المرور عن 6 احرف',
    'what_you_feel': 'ماذا تشعر!',
    'sad_reason': 'سبب شعورك !',
    'no_employees': 'لا يوجد موظفين في هذا القسم',
    'userError': 'من فضلك اختر أعضاء المهمة',
    'taskColorReq': 'لون المهمة مطلوب',
    'no_tasks': 'لا يوجد مهام ',
    'all': 'الكل',
    'no_meetings': 'لا يوجد إجتماعات ',
    'typeReq': 'من فضلك حدد نوع رسالتك',
    'msg_required': 'من فضلك إدخل الرسالة اولا',
    'msgSend': 'تم إرسال رسالتك',
    'type': 'النوع',
    'no_requests': 'لا يوجد طلابات ',
    'time': 'الوقت',
    'late': 'تأخير',
    'no_members': 'لا يوجد أعضاء في فريقك',
    'currentProgress': 'مقياس التقدم',
    'see_sessions': 'إظهار الفترات',
    'no_sheets': 'لا يوجد بيانات مرتب',
    'say_something': 'إرسل تهنأة',
    'no_notifications': 'لا يوجد إشعارات',
    'welcome_aboard': 'اهلا بك',
    'youHave': 'لديك',
    'meeting': 'إجتماع',
    'department': 'قسم',
    'areYouSure': 'هل انت متأكد من',
    'thisRequest': ' هذا الطلب؟',
    'hours': 'الساعات',
    'typeYourMsg': 'إكتب رسالتك ...',
    'pleaseTypeYourMsg': 'من افضلك إدخل رسالتك أولا',
    'connectionLost': 'تم فقد الإتصال بالإنترنت',
    'sessionPending': 'جلستك بانتظار الموافقة',

    'locationAccess':'برجاء تفعيل إذن الوصول الي موقعك',

    'copy_right': 'كل الحقوق محفوظة  © 2020 Kites Keeper.',


    //sign-in
    'hello':'مرحبا',
    'sign_to_account':'سجل دخولك إلي حسابك',
    'invalidEmail':'البريد الإلكتروني غير صحيح',
    'emailRequired':'البريد الإلكتروني مطلوب',
    'password_6':'كلمة السر يجب ألا تقل عن ٦ أرقام او حروف',
    'passwordRequired':'كلمة السر مطلوبة',
    'forgetPassword':'نسيت كلمة السر؟',
    'login':'تسجيل الدخول',
    'invalidEmailPassword':'البريد الإلكتروني أو كلمة السر غير صحيحة',
    'reset':'إرسال',

};