import React from "react";
import {geolocated} from "react-geolocated";

class GeoLocated extends React.Component {

    render() {
        if(this.props.coords){
            localStorage.setItem('latitude',this.props.coords.latitude);
            localStorage.setItem('longitude',this.props.coords.longitude);
            // this.props.setLocation(this.props.coords.latitude,this.props.coords.longitude)
            // this.setState({
            //     latitude: this.props.coords.latitude,
            //     longitude: this.props.coords.longitude,
            // });
        }else{
            localStorage.setItem('latitude',null);
            localStorage.setItem('longitude',null);
        }

        return !this.props.isGeolocationAvailable ? (
            <div></div>
        ) : !this.props.isGeolocationEnabled ? (
            <div></div>
        ) : this.props.coords ? (
            <div></div>
            )

            : (
            <div></div>
            );
    }
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    watchPosition: true,
    userDecisionTimeout: null,
})(GeoLocated);