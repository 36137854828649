import React, {Component, Fragment} from 'react';
import axios from "axios";
import Translate from "react-translate-component";
import LoadingSpinner from "./loading-spinner";

class SalarySheet extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading:true,
            sheets:[]

        }
    }
    componentDidMount(){
            axios.post(window.$urlName + 'salary-sheets', {api_token: localStorage.getItem("token")}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }

            }).then((response) => {
                this.setState({
                    loading:false,
                    sheets:response.data.sheets
                })
            }).catch(function (error) {
                if (error.response.status === 401){
                    window.location.pathname="/"
                }
                console.log(error)
            })
    }
    render() {
        let sheets = this.state.sheets.length ? (
            this.state.sheets.map((sheet,index) => {
                return(
                    <div className="col-lg-4 col-md-4" key={index}>
                        <div className="salaryCard">
                            <h3>{sheet.month},{sheet.year}</h3>
                            <div className="salaryInfo">
                                <div className="table-responsive">
                                    <table className="table">
                                        <tbody>
                                        <tr>
                                            <Translate content="full_salary" component="th"/>
                                            <td>{parseFloat(sheet.fullSalary).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <Translate content="work_day" component="th"/>
                                            <td>{sheet.workDays}</td>
                                        </tr>
                                        <tr>
                                            <Translate content="vacations" component="th"/>
                                            <td>{sheet.vacations}</td>
                                        </tr>
                                        <tr>
                                            <Translate content="absent" component="th"/>
                                            <td>{sheet.absent}</td>
                                        </tr>

                                        <tr>
                                            <Translate content="advance" component="th"/>
                                            <td>{parseFloat(sheet.advance).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <Translate content="deduction" component="th"/>
                                            <td>{parseFloat(sheet.deduction).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <Translate content="net_salary" component="th"/>
                                            <td>{parseFloat(sheet.netSalary).toFixed(2)}</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                    </div>
                )

            })

            ):

            (
                <Translate content="no_sheets" component="p" className="emptyObject"/>

            );
        return (
            <Fragment>
                {this.state.loading ? <div className="loadingMargin">
                        <div className="parent">
                            <div className="child">
                                <LoadingSpinner color={localStorage.getItem("color")} height={'5%'} width={'5%'}/></div>
                        </div>
                    </div> :
                    <div className="container">
                        <div className="row">
                            {sheets}
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

export default SalarySheet