import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
class Footer extends Component{
    render(){
        return (
            <Fragment>
                <div className="copyRight">
                    <Translate content="copy_right"/>
                </div>
            </Fragment>
        );
    }
}
export default Footer;