import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import LoadingSpinner from "./loading-spinner";

class PublicVacations extends Component{
    constructor(props){
        super(props)
        this.state ={
            loading:true,
            annuals:[]
        }

    }
    componentDidMount(){
        let values = {
            api_token : localStorage.getItem("token")
        }
        axios.post(window.$urlName + 'annualVacationsUpdated',values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then((response) => {
            this.setState({
                loading:false,
                annuals : response.data.annuals
            })
        }).catch(function (error) {
            if (error.response.status === 401){
                window.location.pathname="/"
            }
            console.log(error)
        })

    }
    render(){
        let annuals = this.state.annuals.map((annual) => {
            return(
                <tr key={annual.id}>
                    <td>{annual.name}</td>
                    <td>{annual.from}</td>
                    <td>{annual.to}</td>
                </tr>
            )
        })
        return(
            <Fragment>
                {this.state.loading ?  <div className="loadingMargin"> <div className="parent">
                    <div className="child">
                        <LoadingSpinner color={localStorage.getItem("color")} height={'5%'} width={'5%'}/> </div></div></div>:
                    <div className="vacationsTable">
                        <div className="table-responsive">

                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <Translate content="vacation_name" component="th"/>
                                    <Translate content="from" component="th"/>
                                    <Translate content="to" component="th"/>
                                </tr>
                                </thead>
                                <tbody>
                                {annuals}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}
export default PublicVacations