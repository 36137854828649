import React, {Component, Fragment} from 'react';
import {Link} from "react-router-dom"
import Translate from "react-translate-component";
import listIcon from "../component/assest/img/listIcon.svg"
import editIcon from "../component/assest/img/editIcon.svg";
import axios from "axios";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import eye from "../component/assest/img/eye.svg";
import LoadingSpinner from "./loading-spinner";

// import placeholderImg from "../component/assest/img/img-placeholder.png"

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            userInfo: {},
            photo: "",
            profileView: true,
            loading: true,
            disabled: true,
        };

    }

    componentDidMount() {
        let values = {
            api_token: localStorage.getItem("token")
        }
        axios.post(window.$urlName + 'profile', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then((response) => {

            if(response.data.photo === ""){
                this.setState({
                    userInfo: response.data,
                    photo: localStorage.getItem("photo"),
                    loading:false
                })
            }else{
                this.setState({
                    userInfo: response.data,
                    photo: response.data.photo,
                    loading:false
                })
            }
        }).catch(function (error) {
            if (error.response.status === 401){
                window.location.pathname="/"
            }
            console.log(error)
        })
    }

    profileViewToggle = () => {
        this.setState(prevState => ({
            profileView: !prevState.profileView,

        }));

    };

    toggle = () => {
        let file = document.getElementById('file');
        file.click();
    };

    onFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('photo', this.state.file);
        formData.append('api_token', localStorage.getItem("token"));
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        this.setState({
            disabled:false
        });
        axios.post(window.$urlName + 'editPhoto', formData, config)
            .then((response) => {
                localStorage.setItem("photo",this.state.photo)
                window.location.reload();
            })
    };

    onChange = (e) => {
        this.setState({
            file: e.target.files[0],
            photo: URL.createObjectURL(e.target.files[0])
        });
    };

    logOut = () => {
        localStorage.clear();
        window.location.pathname="/";
    };

    render() {
        let phoneRegEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/;

        return (
            <Fragment>
                {this.state.loading ? <div className="loadingMargin"> <div className="parent">
                        <div className="child">
                            <LoadingSpinner color={localStorage.getItem("color")} height={'5%'} width={'5%'}/></div></div>
                    </div> :
                    <div className="container">

                        <div className="row">
                            <div className="profileTable">
                              <div className="row">
                                  <div className="col-lg-8 col-md-8">
                                      <div className="salarySheetButt">
                                          <Link to="salary-sheet"><img src={listIcon} alt="list"/><Translate
                                              content="salary_sheet"/></Link>
                                      </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                      <button className="logOutButt" onClick={this.logOut}><Translate content="log_out"/>
                                      </button>
                                  </div>
                              </div>

                                <div className="uploadImgSec">
                                    <form onSubmit={this.onFormSubmit}>
                                        <input type="file" name="photo" onChange={this.onChange} className="inputFile"
                                               id="file"/>
                                        <div className="imgsquere"
                                             style={{backgroundImage: "url(" + this.state.photo + ")"}}>
                                            {/*<img src={this.state.file}/>*/}
                                            <button type="button" className="editImageButton"><img src={editIcon}
                                                                                                   id="OpenImgUpload"
                                                                                                   onClick={this.toggle}
                                                                                                   width="100%"
                                                                                                   alt="editIcon"/>
                                            </button>
                                        </div>
                                        <button type="submit" className="appButton" disabled={!this.state.disabled}><Translate content="upload"/>
                                        </button>
                                    </form>
                                </div>


                                {this.state.profileView ?

                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                <Translate content="company" component="th"/>
                                                <td>{this.state.userInfo.company}</td>
                                            </tr>
                                            <tr>
                                                <Translate content="hiring_date" component="th"/>
                                                <td>{this.state.userInfo.join_date}</td>
                                            </tr>
                                            <tr>
                                                <Translate content="birth_date" component="th"/>
                                                <td>{this.state.userInfo.birthdate}</td>
                                            </tr>
                                            <tr>
                                                <Translate content="phone" component="th"/>
                                                <td>{this.state.userInfo.phone}</td>
                                            </tr>
                                            <tr>
                                                <Translate content="email" component="th"/>
                                                <td>{this.state.userInfo.email}</td>
                                            </tr>
                                            <tr>
                                                <Translate content="salary" component="th"/>
                                                <td>{this.state.userInfo.salary}</td>
                                            </tr>
                                            <tr>
                                                <Translate content="normal_vacation" component="th"/>
                                                <td>{this.state.userInfo.normalVacations}</td>
                                            </tr>
                                            <tr>
                                                <Translate content="casual_vacation" component="th"/>
                                                <td>{this.state.userInfo.casualVacations}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <button className="editButton" onClick={this.profileViewToggle}><Translate
                                            content="edit"/></button>

                                    </div>
                                    :


                                    <div className="editProfile">
                                        <Formik enableReinitialize
                                                initialValues={{
                                                    api_token: localStorage.getItem("token"),
                                                    name: this.state.userInfo.name,
                                                    email: this.state.userInfo.email,
                                                    phone: this.state.userInfo.phone,
                                                    birthdate: this.state.userInfo.birthdate,
                                                    password: '',
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    name: Yup.string()
                                                        .min(4, <Translate content="name_validation"/>),
                                                    phone: Yup.string()
                                                        .matches(phoneRegEx, <Translate content="phone_validation"/>),
                                                    email: Yup.string()
                                                        .email(<Translate content="email_validation"/>),
                                                    password: Yup.string()
                                                        .min(6, <Translate content="password_validation"/>),

                                                })}
                                                onSubmit={values => {
                                                    axios.post(window.$urlName + 'editAccount', values, {
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Accept': 'application/json'
                                                        }

                                                    }).then((response) => {
                                                        localStorage.setItem("name",values.name)
                                                         window.location.reload()
                                                    }).catch(function (error) {
                                                        console.log(error)
                                                    })

                                                }}
                                        >

                                            {({errors, status, touched}) => (
                                                <Form>
                                                    <div className="form-group">
                                                        <label htmlFor="name"><Translate content="name"/></label>
                                                        <Field name="name" id="name" type="text"
                                                               className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="name" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="phone"><Translate content="phone"/></label>
                                                        <Field name="phone" id="phone" type="tel"
                                                               className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="phone" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="email"><Translate content="email"/></label>
                                                        <Field name="email" id="email" type="email"
                                                               className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="email" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="birthdate"><Translate content="birth_date"/></label>
                                                        <Field name="birthdate" id="birthdate" type="date"
                                                               className={'form-control' + (errors.birthdate && touched.birthdate ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="birthdate" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="password"><Translate
                                                            content="password"/></label>
                                                        <div style={{position: "relative"}}>
                                                            <Field name="password" id="password"
                                                                   type={this.state.hidden ? "password" : "text"}
                                                                   className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                                            <span className="showButt" onClick={this.toggleShow}><img
                                                                src={eye} alt="eye"/></span>
                                                            <ErrorMessage name="password" component="div"
                                                                          className="invalid-feedback"/>
                                                        </div>
                                                    </div>
                                                    <button type="submit"><Translate content="save"/></button>

                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                }

                            </div>
                        </div>

                    </div>
                }
            </Fragment>
        )
    }
}

export default Profile