import React, {Component, Fragment} from 'react';
import {Redirect} from "react-router-dom"
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import Translate from "react-translate-component";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import LoadingSpinner from "./loading-spinner";
import imgPlaceholder from "../component/assest/img/img-placeholder.png";

class EditTask extends Component{
    constructor(props) {
        super(props)
        this.state ={
            task :this.props.location.state.task,
            users: [],
            ids: [],
            newIds: [],
            color: "",
            loading: true,
            redirect:false,
            disabled:true
        }
    }

    componentDidMount(){
        let values = {
            api_token: localStorage.getItem("token"),
            type:'team'
        };
        axios.post(window.$urlName + 'company-users', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then((response) => {
            this.setState({
                users: response.data.data,
                loading: false
            })

        }).catch(function (error) {
            if (error.response.status === 401){
                window.location.pathname="/"
            }
            console.log(error)
        })
        let employees = this.props.location.state.task.employees
        let {ids} = this.state
        for(const employee of employees){
            ids.push(employee.id)
        }
        this.setState({
            color : this.props.location.state.task.color,
            ids : ids
        })
    }

    userChecked = (id) => {
        let {ids} = this.state
        if(ids.includes(id)){
            var index = ids.indexOf(id);
            ids.splice(index, 1);
            document.getElementById(id).className =
                document.getElementById(id).className.replace('checked','taskTeam');
        }else{
            ids.push(id);
            document.getElementById(id).className = "checked";
        }

        if(ids.length > 0){
            this.setState({
                usersError: false,
            })
        }
    }

    selectedColor = (id,color) =>{
        this.setState({
            color:color,
        })
    }

    render(){
        const {redirect} = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: "/my-tasks",
            }}
            />
        }
        const params = {
            slidesPerView: 5,
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 5,
                },
                768: {
                    slidesPerView: 4,
                },
                640: {
                    slidesPerView: 3,
                },
                320: {
                    slidesPerView: 1,
                }
            },
            // autoplay: {
            //     delay: 3000,
            // },


        };
        let users = this.state.users.map((user) => {
            return (
                <div key={user.id}>
                    <div className={this.state.ids.includes(user.id) ? "checked" : "taskTeam"} onClick={()=>this.userChecked(user.id)} id={user.id}>
                        {user.image === "" ?
                            <div className="profileSideImg" style={{
                                backgroundImage: "url(" + imgPlaceholder + ")",
                            }}></div>
                            :
                            <div className="profileSideImg" style={{
                                backgroundImage: "url(" + user.image + ")",
                            }}></div>
                        }
                        <p>{user.name}</p>
                    </div>
                </div>
            )
        })
        return(
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="taskForm">
                                <Translate content="edit" component="h3"/>
                                <Formik enableReinitialize
                                    initialValues={{
                                        api_token: localStorage.getItem("token"),
                                        id: this.state.task.id,
                                        color: this.state.color,
                                        title: this.state.task.title,
                                        project_name: this.state.task.project_name,
                                        deadline: this.state.task.deadline,
                                        description: this.state.task.description,
                                        ids: this.state.ids,
                                    }}
                                    validationSchema={Yup.object().shape({
                                        title: Yup.string()
                                            .required(<Translate content="title_required"/>),

                                        project_name: Yup.string()
                                            .required(<Translate content="project_required"/>),

                                        deadline: Yup.date()
                                            .required(<Translate content="dateLine_required"/>),

                                        description: Yup.string()
                                            .required(<Translate content="about_required"/>),

                                    })}
                                    onSubmit={values => {
                                            this.setState({
                                                disabled:false,
                                            })
                                            values.ids = JSON.stringify(this.state.ids);
                                            axios.post(window.$urlName + 'edit-task', values, {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Accept': 'application/json'
                                                }

                                            }).then((response) => {
                                                this.setState({
                                                    redirect:true

                                                })
                                            }).catch(function (error) {
                                                console.log(error)
                                            })
                                    }}
                                >
                                    {({errors, status, touched}) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <label htmlFor="title"><Translate content="title"/></label>
                                                </div>
                                                <div className="col-lg-9 col-md-9">
                                                    <div className="form-group">
                                                        <Field name="title" id="title" type="text"
                                                               className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="title" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <label htmlFor="project_name"><Translate content="project"/></label>
                                                </div>
                                                <div className="col-lg-9 col-md-9">
                                                    <div className="form-group">
                                                        <Field name="project_name" id="project_name" type="text"
                                                               className={'form-control' + (errors.project_name && touched.project_name ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="project_name" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <label htmlFor="deadline"><Translate content="dateLine"/></label>
                                                </div>
                                                <div className="col-lg-9 col-md-9">
                                                    <div className="form-group">
                                                        <Field name="deadline" id="deadline" type="date"
                                                               className={'form-control' + (errors.deadline && touched.deadline ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="deadline" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.taskColor ? <Translate className="userError" content="taskColorReq" component="p"/>:null}
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <label htmlFor="colorCode"><Translate content="colorCode"/></label>
                                                </div>
                                                <div className="col-lg-9 col-md-9">
                                                    <div className="form-group">
                                                        <div className={`colorSquare ${this.state.color === "#B724D1" ? "colorChecked" : ""}`} style={{backgroundColor:"#B724D1"}} id="color1" onClick={()=>this.selectedColor("color1","#B724D1")}></div>
                                                        <div className={`colorSquare ${this.state.color === "#24D1D1" ? "colorChecked" : ""}`} style={{backgroundColor:"#24D1D1"}} id="color2" onClick={()=>this.selectedColor("color2","#24D1D1")}></div>
                                                        <div className={`colorSquare ${this.state.color === "#103ED6" ? "colorChecked" : ""}`} style={{backgroundColor:"#103ED6"}} id="color3" onClick={()=>this.selectedColor("color3","#103ED6")}></div>
                                                        <div className={`colorSquare ${this.state.color === "#FCEA1B" ? "colorChecked" : ""}`} style={{backgroundColor:"#FCEA1B"}} id="color4" onClick={()=>this.selectedColor("color4","#FCEA1B")}></div>
                                                        <div className={`colorSquare ${this.state.color === "#18D610" ? "colorChecked" : ""}`} style={{backgroundColor:"#18D610"}} id="color5" onClick={()=>this.selectedColor("color5","#18D610")}></div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <Field component="textarea" name="description" id="description"
                                                       className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')}
                                                       placeholder={localStorage.getItem("lang") === "ar"? "عن المهمة": "About Project"}/>
                                                <ErrorMessage name="description" component="div"
                                                              className="invalid-feedback"/>
                                            </div>
                                            <div>
                                                {this.state.usersError ? <Translate className="userError" content="userError" component="p"/>:null}
                                                <label htmlFor="addTaskTo"><Translate content="addTaskTo"/></label>

                                                {this.state.loading ?

                                                    <LoadingSpinner color={localStorage.getItem("color")}
                                                                    height={'5%'} width={'5%'}/>
                                                    :
                                                    <div>
                                                        {this.state.users.length ?
                                                            <Swiper {...params}>
                                                                {users}
                                                            </Swiper>
                                                            : null}
                                                    </div>
                                                }
                                            </div>

                                            <button type="submit" disabled={!this.state.disabled}><Translate content="save_task"/></button>


                                        </Form>
                                    )}
                                </Formik>


                            </div>
                        </div>
                        {/*<div className="col-lg-4 col-md-4 rightSide">*/}
                        {/*<TaskRightSide/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Fragment>
        )
    }

}
export default EditTask