import React, {Component,Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Redirect} from "react-router-dom"
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import axios from "axios";
import eye from "../component/assest/img/eye.svg";
import logo from "../component/assest/img/logo.svg"
import {Modal, ModalBody} from "reactstrap";
import LoadingSpinner from "./loading-spinner";
import imgPlaceholder from "../component/assest/img/img-placeholder.png";

import ops from "../component/assest/img/ops.svg";
import Translate from "react-translate-component";

class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hidden: true,
            msgEn:"",
            ErrorModel: false,
            loading: false,
            redirect:false
        }
    }

    toggleShow = () => {
        this.setState({ hidden: !this.state.hidden });
    }

    errorMsgModel = () => {
        this.setState(prevState => ({
            ErrorModel: !prevState.ErrorModel,
        }));
    };

    render(){
        const {redirect} = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: "/dashboard",
            }}
            />
        }

        if(localStorage.getItem("token")){
            if(localStorage.getItem("token").length > 10){
                return <Redirect to={{
                    pathname: "/dashboard",
                }}
                />
            }
        }



        return(
            <Fragment>

                <div className="container-fluid signInBody" style={{height:"100vh"}}>
                    <div className="parent">
                        <div className="child">
                            <div className="signInForm">
                        <img src={logo} alt="logo" className="signInLogo"/>
                        <h2><Translate content="hello"/></h2>
                        <p><Translate content="sign_to_account"/></p>
                         {this.state.loading ? <LoadingSpinner height={'10%'} width={'10%'}/> :null}
                                <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email(<Translate content="invalidEmail"/>)
                                    .required(<Translate content="emailRequired"/>),
                       
                                password: Yup.string()
                                    .min(6, <Translate content="password_6"/>)
                                    .required(<Translate content="passwordRequired"/>),

                            })}
                            onSubmit={values => {
                                this.setState({
                                    loading : true
                                })
                                values.app_version = '2.0.0';
                                values.platform = 'web';
                                axios.post(window.$urlName + 'login',values, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                    }

                                }).then((response) => {
                                    localStorage.setItem("token", response.data.api_token);
                                    localStorage.setItem("color", response.data.companyColorHEX);
                                    localStorage.setItem("name", response.data.name);
                                    localStorage.setItem("position", response.data.position);
                                    if(response.data.photo === ""){
                                        localStorage.setItem("photo", imgPlaceholder);
                                    }else {
                                        localStorage.setItem("photo", response.data.photo);
                                    }
                                    localStorage.setItem("logo", response.data.companyLogo);
                                    document.documentElement.style.setProperty('--themeColor', localStorage.getItem("color"));

                                    this.setState({
                                        loading : false,
                                        redirect:true
                                    })
                                }).catch(function(error){
                                        this.setState({
                                            msgEn:<Translate content="invalidEmailPassword"/>,
                                            ErrorModel:true,
                                            loading:false
                                        });
                                }.bind(this))

                            }}
                        >

                            {({errors, status, touched}) => (
                                <Form>
                                    <div className="form-group">
                                        <Field name="email" id="email" type="email"
                                               className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} attributes={{placeholder: 'email'}}/>
                                        <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                    </div>
                                    <div className="form-group">
                                        <div style={{position:"relative"}}>

                                            <Field name="password" id="password" type={this.state.hidden ? "password" : "text"}
                                                   className={'form-control' +  (errors.password && touched.password ? ' is-invalid' : '')} attributes={{placeholder: 'password'}}/>
                                            <span className="showButt" onClick={this.toggleShow}><img src={eye} alt="eye"/></span>
                                            <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <Link to="/forget-password" className="forgetLink"><Translate content="forgetPassword"/></Link>
                                    <button type="submit"><Translate content="login"/></button>
                           

                                </Form>
                            )}
                        </Formik>
                    </div>
                        </div>
                    </div>
                    </div>

                <Modal isOpen={this.state.ErrorModel} toggle={this.errorMsgModel} className="modalBody">
                    <ModalBody>
                     <div className="errorBody">
                         <img src={ops} alt="error"/>
                         <h3>OPSS!</h3>
                         <p>{this.state.msgEn}</p>
                     </div>
                    </ModalBody>
                </Modal>

            </Fragment>
        )
    }
}
export default SignIn