import React, {Component, Fragment} from 'react';
import {Redirect} from "react-router-dom"
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import axios from "axios";
import * as Yup from "yup";
// import MeetingRightSide from "./meeting-right-side";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Translate from "react-translate-component";
import LoadingSpinner from "./loading-spinner";
import toggle from "../component/assest/img/toggle.svg";
import imgPlaceholder from "../component/assest/img/img-placeholder.png";

class AddMeeting extends Component {
    constructor(props) {
        super(props);
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const hh = String(today.getHours()).padStart(2, '0'); // => 9
        const m = String(today.getMinutes()).padStart(2, '0'); // =>  30
        const time = hh + ':' + m;
        const date = yyyy + '-' + mm + '-' + dd;

        this.state = {
            ids: [],
            users: [],
            color: "",
            loading: true,
            usersError: false,
            redirect: false,
            disabled: true,
            minDate:date,
            minTime:time
        }
    }

    componentDidMount() {
        let values = {
            api_token: localStorage.getItem("token")
        };
        axios.post(window.$urlName + 'company-users', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({
                users: response.data.data,
                loading: false
            })

        }).catch(function (error) {
            if (error.response.status === 401) {
                window.location.pathname = "/"
            }
            console.log(error)
        })
    }

    userChecked = (id) => {
        let {ids} = this.state
        if(ids.includes(id)){
            var index = ids.indexOf(id);
            ids.splice(index, 1);
            document.getElementById(id).className =
                document.getElementById(id).className.replace('checked','taskTeam');
        }else{
            ids.push(id);
            document.getElementById(id).className = "checked";
        }

        if(ids.length > 0){
            this.setState({
                usersError: false,
            })
        }
    };

    render() {
        const {redirect} = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: "/my-meetings",
            }}
            />
        }

        const params = {
            slidesPerView: 5,
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 5,
                },
                768: {
                    slidesPerView: 4,
                },
                640: {
                    slidesPerView: 3,
                },
                320: {
                    slidesPerView: 1,
                }
            },
        };

        let users = this.state.users.map((user) => {
            return (
                <div key={user.id}>
                    <div className="taskTeam" onClick={() => this.userChecked(user.id)} id={user.id}>
                        {user.image === "" ?
                            <div className="profileSideImg" style={{
                                backgroundImage: "url(" + imgPlaceholder + ")",
                            }}></div>
                            :
                            <div className="profileSideImg" style={{
                                backgroundImage: "url(" + user.image + ")",
                            }}></div>
                        }
                        <p>{user.name}</p>
                    </div>
                </div>
            )
        })

        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="taskForm">
                                <Translate content="add_new_meeting" component="h3"/>
                                <Formik
                                    initialValues={{
                                        api_token: localStorage.getItem("token"),
                                        title: '',
                                        date: '',
                                        time_from: '',
                                        time_to: '',
                                        description: '',
                                        ids: [],
                                    }}
                                    validationSchema={Yup.object().shape({
                                        title: Yup.string()
                                            .required(<Translate content="title_required"/>),

                                        date: Yup.date()
                                            .required(<Translate content="date_required"/>),

                                        time_from: Yup.string()
                                            .required(<Translate content="time_required"/>),

                                        time_to: Yup.string()
                                            .required(<Translate content="time_required"/>),

                                        description: Yup.string()
                                            .required(<Translate content="description_required"/>),
                                    })}
                                    onSubmit={values => {
                                        const today = new Date();
                                        const dd = String(today.getDate()).padStart(2, '0');
                                        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                                        const yyyy = today.getFullYear();

                                        if(Date.parse(yyyy+'-'+mm+'-'+dd+' '+values.time_from) >= Date.parse(yyyy+'-'+mm+'-'+dd+' '+values.time_to)){
                                            alert('Time to must be greater than time from')
                                        }else{
                                            if (this.state.ids.length) {
                                                this.setState({
                                                    usersError: false,
                                                    disabled: false,
                                                })
                                                values.ids = JSON.stringify(this.state.ids);
                                                axios.post(window.$urlName + 'add-meeting', values, {
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Accept': 'application/json'
                                                    }
                                                }).then((response) => {
                                                    this.setState({
                                                        redirect: true
                                                    })
                                                }).catch(function (error) {
                                                    console.log(error)
                                                })
                                            } else {
                                                this.setState({
                                                    usersError: true
                                                })
                                            }
                                        }
                                    }}
                                >

                                    {({errors, status, touched}) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <label htmlFor="title"><Translate content="title"/></label>
                                                </div>
                                                <div className="col-lg-9 col-md-9">
                                                    <div className="form-group">
                                                        <Field name="title" id="title" type="text"
                                                               className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="title" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <label htmlFor="date"><Translate content="date"/></label>
                                                </div>
                                                <div className="col-lg-9 col-md-9">
                                                    <div className="form-group">
                                                        <Field name="date" id="date" type="date" min={this.state.minDate}
                                                               className={'form-control' + (errors.date && touched.date ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="date" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-5 col-md-5">
                                                    <label htmlFor="time_from"><Translate content="time_from"/></label>
                                                    <div className="form-group">
                                                        <Field name="time_from" id="time_from" type="time" min={this.state.minTime}
                                                               className={'form-control' + (errors.time_from && touched.time_from ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="time_from" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <img src={toggle} alt="toggle" className="toggleImg"/>
                                                </div>
                                                <div className="col-lg-5 col-md-5">
                                                    <label htmlFor="time_to"><Translate content="time_to"/></label>
                                                    <div className="form-group">
                                                        <Field name="time_to" id="time_to" type="time"
                                                               className={'form-control' + (errors.time_to && touched.time_to ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="time_to" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <Field component="textarea" name="description" id="description"
                                                       className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')}
                                                       placeholder={localStorage.getItem("lang") === "ar" ? "الوصف" : "Description"}/>
                                                <ErrorMessage name="description" component="div"
                                                              className="invalid-feedback"/>
                                            </div>


                                            <div>
                                                {this.state.usersError ?
                                                    <Translate className="userError" content="userError"
                                                               component="p"/> : null}
                                                <label><Translate content="invite_people"/></label>

                                                {this.state.loading ?

                                                    <LoadingSpinner color={localStorage.getItem("color")}
                                                                    height={'5%'} width={'5%'}/>
                                                    :
                                                    <div>
                                                        {this.state.users.length ?
                                                            <Swiper {...params}>
                                                                {users}
                                                            </Swiper>
                                                            : null}
                                                    </div>
                                                }
                                            </div>
                                            <button type="submit" disabled={!this.state.disabled}><Translate
                                                content="save_meeting"/></button>


                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                        {/*<div className="col-lg-4 col-md-4 rightSide">*/}
                        {/*<MeetingRightSide/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default AddMeeting