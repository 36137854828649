import React, {Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import SignIn from "./view/sign-in";
import "./component/assest/css/en.css"
import counterpart from "counterpart";
import ar from "./component/lang/ar";
import en from "./component/lang/en";
import ForgetPassword from "./view/forget-password";
import Sidebar from "./view/sidebar";
import Header from "./view/header";
import Home from "./view/home";
import Profile from "./view/profile";
import Chat from "./view/chat";
import SalarySheet from "./view/salary-sheet";
import MyTeam from "./view/my-team";
import CheckIn from "./view/check-in-history";
import PublicVacations from "./view/public-vacations";
import Help from "./view/help";
import MyRequests from "./view/my-requests";
import MyTasks from "./view/my-tasks";
import AddTask from "./view/add-task";
import MyMeetings from "./view/my-meetings";
import AddMeeting from "./view/add-meeting";
import Footer from "./view/footer";
import EditTask from "./view/edit-task";
import EditMeeting from "./view/edit-meeting";
import MemberProfile from "./view/member-profile";
import MemberTasks from "./view/member-tasks";
import MemberRequests from "./view/member-requests";
import pageError from "./view/404";
import NetworkDetector from './networkDetector';
import { askForPermissioToReceiveNotifications } from './view/firebase';

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ar', ar);
counterpart.setLocale('en');


class App extends Component {
    async componentDidMount() {
        askForPermissioToReceiveNotifications();

        // const messaging = firebase.messaging();
        //
        // Notification.requestPermission().then(()=>{
        //     return messaging.getToken()
        // }).then(token=>{
        //     if (localStorage.getItem('pushToken') !== token) {
        //         localStorage.setItem('pushToken', token);
        //     }
        // }).catch((err)=>{
        //     console.log(err);
        // });

        let lang = localStorage.getItem("lang");
        if (!lang) {
            localStorage.setItem("lang", 'en');
            await import(`${'./component/assest/css/en.css'}`);
            counterpart.registerTranslations('en', en);
            counterpart.setLocale('en');
        } else if (lang === 'en') {
            localStorage.setItem("lang", 'en');
            await import(`${'./component/assest/css/en.css'}`);
            counterpart.registerTranslations('en', en);
            counterpart.setLocale('en');
        } else {
            localStorage.setItem("lang", 'ar');
            await import(`${'./component/assest/css/ar.css'}`);
            counterpart.registerTranslations('ar', ar);
            counterpart.setLocale('ar');
        }
        document.documentElement.style.setProperty('--themeColor', localStorage.getItem("color"));
    }

    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    <Switch>
                        <Route exact path="/" component={SignIn}/>
                        <Route path="/forget-password" component={ForgetPassword}/>
                        <Route path="/404" component={pageError}/>

                        <React.Fragment>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 noPadding">
                                        <Sidebar/>
                                    </div>
                                    <div className="col-lg-9 col-md-9 noPadding">
                                        <Header/>
                                        <div className="pageBody">
                                            <Route path="/dashboard" component={Home}/>
                                            <Route path="/profile" component={Profile}/>
                                            <Route path="/chat" component={Chat}/>
                                            <Route path="/salary-sheet" component={SalarySheet}/>
                                            <Route path="/my-team" component={MyTeam}/>
                                            <Route path="/check-in-history" component={CheckIn}/>
                                            <Route path="/public-vacations" component={PublicVacations}/>
                                            <Route path="/help" component={Help}/>
                                            <Route path="/my-requests" component={MyRequests}/>
                                            <Route path="/my-tasks" component={MyTasks}/>
                                            <Route path="/add-task" component={AddTask}/>
                                            <Route path="/edit-task" component={EditTask}/>
                                            <Route path="/my-meetings" component={MyMeetings}/>
                                            <Route path="/add-meeting" component={AddMeeting}/>
                                            <Route path="/edit-meeting" component={EditMeeting}/>
                                            <Route path="/member-profile" component={MemberProfile}/>
                                            <Route path="/member-tasks" component={MemberTasks}/>
                                            <Route path="/member-requests" component={MemberRequests}/>
                                            <Footer/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </React.Fragment>
                        {/*<Routes/>*/}

                    </Switch>

                </div>
            </BrowserRouter>

        )
    }

}


export default NetworkDetector(App);
