import React, {Component,Fragment} from 'react';
import error from "../component/assest/img/404.jpg"

class pageError extends Component {
    render(){
        return(
            <Fragment>
                <img src={error} alt="404" width="100%"/>
            </Fragment>
        )
    }
}
export default pageError