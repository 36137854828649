import React, {Component, Fragment} from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import axios from "axios";
import Translate from "react-translate-component";
import pauseImg from "../component/assest/img/pause.svg"
import playImg from "../component/assest/img/play.svg"
import normal from "../component/assest/img/happy.svg"
import normalActive from "../component/assest/img/happyActive.svg"
import happy from "../component/assest/img/veryHappy.svg"
import happyActive from "../component/assest/img/veryHappyActive.svg"
import sad from "../component/assest/img/sad.svg"
import sadActive from "../component/assest/img/sadActive.svg"
import middle from "../component/assest/img/verySad.svg"
import middleActive from "../component/assest/img/verySadActive.svg"
import {Modal, ModalBody} from "reactstrap";
import LoadingSpinner from "./loading-spinner";
import imgPlaceholder from "../component/assest/img/img-placeholder.png"
import balones from "../component/assest/img/balones.png"
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import GeoLocated from "./geolocated";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            homeObject: {},
            departments: [],
            birthdays: [],
            errorModel: false,
            reactModel: false,
            birthdaysModel: false,
            birthModel: false,
            show: false,
            sessionState:false,
            sadInput: false,
            sad: sad,
            middle: middle,
            normal: normal,
            happy: happy,
            userState: "",
            reason: "",
            loading: true,
            name: "",
            id: "",
            msg: "",
            success: false,
            disabled: true,

            break: false,
            timerStarted: false,
            timerStopped: true,
            hours: 0,
            minutes: 0,
            seconds: 0,
        }

    }

    componentDidMount() {
        let values = {
            api_token: localStorage.getItem("token")
        };
        axios.post(window.$urlName + 'homePage', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({
                homeObject: response.data,
                departments: response.data.departments,
                loading: false,
            });

            localStorage.setItem("company_firebase", response.data.company_firebase);
            localStorage.setItem("user_firebase", response.data.user_firebase);
            localStorage.setItem("hasTeam", response.data.hasTeam);

            if (response.data.show === "yes") {
                this.setState({
                    show: true,
                    break: response.data.break,
                    hours: response.data.hours,
                    minutes: response.data.minutes,
                    seconds: response.data.seconds,
                });

                if (response.data.sessionState === "true") {
                    this.setState({
                        sessionState: true,
                    })
                    this.handleTimerStart();
                }
                this.handleTimerStart();
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.setItem("token", null);
                window.location.pathname = "/";
            }
            console.log(error)
        });

        axios.post(window.$urlName + 'checkBirthday', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then((response) => {
            this.setState({
                birthdays: response.data.data
            })

        }).catch(function (error) {
            console.log(error)
        });

        let attrs = {
            api_token: localStorage.getItem("token"),
            pushToken: localStorage.getItem("pushToken")
        };
        axios.post(window.$urlName + 'update-push-token', attrs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.setItem("token", null);
                window.location.pathname = "/";
            }
        });

    }

    sendReactModel = () => {
        this.setState(prevState => ({
            reactModel: !prevState.reactModel,
        }));
    };

    errorModel = () => {
        this.setState(prevState => ({
            errorModel: !prevState.errorModel,
        }));
    };

    birthdaysTypeModel = () => {
        this.setState(prevState => ({
            birthdaysModel: !prevState.birthdaysModel,
        }));
    };

    birthCardModel = (id, name) => {
        this.setState(prevState => ({
            birthModel: !prevState.birthModel,
            birthdaysModel: !prevState.birthdaysModel,
        }));
        this.setState({
            name: name,
            id: id,
        })
    }

    startCount = () => {
        let values = {
            api_token: localStorage.getItem("token"),
            longitude: localStorage.getItem("longitude"),
            latitude: localStorage.getItem("latitude"),
        };
        if(localStorage.getItem("longitude") !== 'null' && localStorage.getItem("latitude") !== 'null') {
            axios.post(window.$urlName + 'checkIn', values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then((response) => {
                if (response.data.message === 'success') {
                    this.setState({
                        show: true
                    })
                    this.handleTimerStart.bind(this);
                    window.location.reload();
                }
            }).catch(function (error) {
                console.log(error);
            });
        }else{
            this.errorModel();
        }
    };

    takeBreak = () => {
        let values = {
            api_token: localStorage.getItem("token"),
            longitude: localStorage.getItem("longitude"),
            latitude: localStorage.getItem("latitude"),
            type: 'break'
        };
        if(localStorage.getItem("longitude") !== 'null' && localStorage.getItem("latitude") !== 'null') {
            axios.post(window.$urlName + 'checkIn', values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then((response) => {
                if (response.data.message === 'success') {
                    this.handleTimerStart.bind(this);
                    window.location.reload();
                }
            });
        }else{
            this.errorModel();
        }
    };

    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    checkOutSubmit = () => {
        // this.setState({
        //     loading: true
        // });
        let values = {
            api_token: localStorage.getItem("token"),
            longitude: localStorage.getItem("longitude"),
            latitude: localStorage.getItem("latitude"),
            state: this.state.userState,
            reason: this.state.reason
        };
        if(localStorage.getItem("longitude") !== 'null' && localStorage.getItem("latitude") !== 'null') {
            axios.post(window.$urlName + 'checkIn', values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then((response) => {
                if (response.data.message === 'success') {
                    this.handleTimerStop.bind(this);
                    window.location.reload();
                }
            });
        }else{
            this.errorModel();
        }
    }

    reactClick = (react) => {
        if (react === "sad") {
            this.setState({
                sadInput: true,
                sad: sadActive,
                middle: middle,
                normal: normal,
                happy: happy,
                userState: "sad"
            })
        } else if (react === "middle") {
            this.setState({
                sadInput: false,
                sad: sad,
                middle: middleActive,
                normal: normal,
                happy: happy,
                userState: "middle"
            })
        } else if (react === "normal") {
            this.setState({
                sadInput: false,
                sad: sad,
                middle: middle,
                normal: normalActive,
                happy: happy,
                userState: "normal"
            })
        } else {
            this.setState({
                sadInput: false,
                sad: sad,
                middle: middle,
                normal: normal,
                happy: happyActive,
                userState: "happy"
            })
        }
    }

    getEmployees = (employees) => {
        return (
            employees.map((employee, index) => {
                return (
                    <div className="profileSwipe" key={index}>
                        {employee.image === "" ?
                            <div className="profileSideImg" style={{
                                backgroundImage: "url(" + imgPlaceholder + ")",
                            }}></div>
                            :
                            <div className="profileSideImg" style={{
                                backgroundImage: "url(" + employee.image + ")",
                            }}></div>
                        }
                        <p>{employee.name}</p>
                    </div>
                )
            })
        )
    }

    handleTimerStart() {
        if (this.state.timerStopped) {
            this.timer = setInterval(() => {
                this.setState({timerStarted: true, timerStopped: false});
                if (this.state.timerStarted) {
                    if (this.state.seconds >= 60) {
                        this.setState((prevState) => ({minutes: prevState.minutes + 1, seconds: 0}));
                    }
                    if (this.state.minutes >= 60) {
                        this.setState((prevState) => ({hours: prevState.hours + 1, minutes: 0, seconds: 0}));
                    }
                    this.setState((prevState) => ({seconds: prevState.seconds + 1}));
                }
            }, 1000);
        }
    }

    handleTimerStop() {
        this.setState({timerStarted: false, timerStopped: true});
        clearInterval(this.timer);
    }

    render() {
        const params = {
            slidesPerView: 4,
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 3,
                },
                640: {
                    slidesPerView: 3,
                },
                320: {
                    slidesPerView: 1,
                }
            }
        };

        let departments = this.state.departments.map((department) => {
            return (
                <div key={department.name}>
                    <div className="row">
                        <div className="col-lg-3 col-md-3">
                            <div className="depName">
                                <div className="activeCircle">
                                </div>
                                <span>{department.name}</span>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9">
                            {department.employees.length ?
                                <Swiper {...params}>
                                    {this.getEmployees(department.employees)}
                                </Swiper>
                                : <Translate content="no_employees" component="p" className="emptyObject"/>}
                        </div>
                    </div>
                    <hr/>
                </div>
            )
        })

        let birthdaysCards = this.state.birthdays.map((card, index) => {
            return (
                <div key={index}>
                    <div className="birthdayCard">
                        <div className="birthdayImg">
                            {card.image === "" ?
                                <div className="profileSideImg" style={{
                                    backgroundImage: "url(" + imgPlaceholder + ")",
                                }}></div>
                                :
                                <div className="profileSideImg" style={{
                                    backgroundImage: "url(" + card.image + ")",
                                }}></div>
                            }
                            <img src={balones} alt="balones" className="balones"/>
                        </div>
                        <p>{card.name}</p>
                        <button onClick={() => this.birthCardModel(card.id, card.name)}><Translate
                            content="say_something"/></button>
                    </div>
                    <hr/>
                </div>
            )
        })

        let hoursPercentage = 0;
        let vacationsPercentage = 0;
        let leavingPercentage = 0;

        if (this.state.homeObject.hoursTotal > 0) {
            hoursPercentage = (this.state.homeObject.hoursValue / parseFloat(this.state.homeObject.hoursTotal)) * 100;
        }
        if (this.state.homeObject.vacationsTotal > 0) {
            vacationsPercentage = (this.state.homeObject.vacationsValue / parseFloat(this.state.homeObject.vacationsTotal)) * 100;
        }
        if (this.state.homeObject.leavesTotal) {
            leavingPercentage = (this.state.homeObject.leavesValue / this.state.homeObject.leavesTotal) * 100;
        }


        return (
            <Fragment>
                {this.state.loading ? <div className="loadingMargin">
                        <div className="parent">
                            <div className="child">
                                <LoadingSpinner color={localStorage.getItem("color")} height={'5%'} width={'5%'}/></div>
                        </div>
                    </div> :
                    <div>
                        <GeoLocated/>
                        <div className="progressBar">
                            <div className="singleProgressSec">
                                <CircularProgressbar
                                    className="hoursProgress"
                                    value={hoursPercentage}
                                    text={`${parseInt(hoursPercentage)}%`}
                                />
                                <Translate content="hours" component="p"/>
                            </div>
                            <div className="singleProgressSec">
                                <CircularProgressbar
                                    className="vacationsProgress"
                                    value={vacationsPercentage}
                                    text={`${parseInt(vacationsPercentage)}%`}
                                />
                                <Translate content="vacations" component="p"/>
                            </div>
                            <div className="singleProgressSec">

                                <CircularProgressbar
                                    className="leavingProgress"
                                    value={leavingPercentage}
                                    text={`${parseInt(leavingPercentage)}%`}
                                />
                                <Translate content="leaving" component="p"/>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="counterBody">
                                    {this.state.show ?
                                        <div className={this.state.break ==='true'? "counterSec counterBreak" :"counterSec"}>
                                            { `${(this.state.hours < 10 ? `0${this.state.hours}` : this.state.hours)}` + ":"
                                            + `${(this.state.minutes < 10 ? `0${this.state.minutes}` : this.state.minutes)}` + ":"
                                            + `${(this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds)}`}
                                        </div>
                                        : null}
                                    {this.state.show && !this.state.sessionState?
                                        <Translate content="sessionPending" component="p"/>:null
                                    }
                                    <div className="actionButtons">
                                        {this.state.show ? <button onClick={this.takeBreak} className="pauseButton"><img src={pauseImg} alt="pause"/></button>:null}
                                        {this.state.show ? <button onClick={this.sendReactModel} className="startButton"><Translate content="check_out"/></button>:null}
                                        {this.state.show ? <button onClick={this.takeBreak}  className="platButton"><img src={playImg} alt="play"/></button>:null}
                                        {!this.state.show ? <button onClick={this.startCount} className="startButton startButton2"><Translate content="check_in"/></button>:null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.birthdays.length ? <div className="birthdayIcon">
                            <i className="fa fa-gift" onClick={this.birthdaysTypeModel}></i>
                        </div> : null}

                        {this.state.departments.length > 0 ?
                        <div className="container-fluid activeDepSec">
                            {departments}
                        </div> :null}

                        <Modal isOpen={this.state.reactModel} toggle={this.sendReactModel} className="modalBody">
                            <ModalBody>
                                <div className="reactSec">
                                    <Translate content="what_you_feel" component="h3"/>
                                    <img src={this.state.sad} onClick={() => this.reactClick("sad")} alt="sad"
                                         id="sad"/>
                                    <img src={this.state.middle} onClick={() => this.reactClick("middle")} alt="middle"
                                         id="middle"/>
                                    <img src={this.state.normal} onClick={() => this.reactClick("normal")} alt="normal"
                                         id="normal"/>
                                    <img src={this.state.happy} onClick={() => this.reactClick("happy")} alt="happy"
                                         id="happy"/>
                                    {this.state.sadInput ?
                                        <div className="form-group">
                                            <label htmlFor="reason"><Translate content="sad_reason"/></label>
                                            <textarea className="form-control" id="reason" name="reason"
                                                      value={this.state.reason} onChange={this.handelChange}></textarea>
                                        </div>
                                        : null}
                                    {this.state.loading ?
                                        <LoadingSpinner color={"#30539A"} height={'5%'} width={'5%'}/> : null}
                                    <button disabled={!this.state.userState} onClick={this.checkOutSubmit}><Translate
                                        content="send"/></button>
                                </div>
                            </ModalBody>
                        </Modal>

                        <Modal isOpen={this.state.errorModel} toggle={this.errorModel} className="modalBody">
                            <ModalBody>
                                <div className="reactSec" style={{padding:"50px"}}>
                                    <Translate content="locationAccess" component="h3"/>
                                </div>
                            </ModalBody>
                        </Modal>

                        <Modal isOpen={this.state.birthdaysModel} toggle={this.birthdaysTypeModel}
                               className="modalBody">
                            <ModalBody>
                                {birthdaysCards}
                            </ModalBody>
                        </Modal>

                        <Modal isOpen={this.state.birthModel} toggle={this.birthCardModel}
                               className="birthdaymodalBody">
                            <ModalBody>
                                <div className="birthdaySec">
                                    <div className="birthdayTemp">
                                        <p>{localStorage.getItem("lang") === "ar" ? "أنه عيد ميلاد " + this.state.name + " إكتب له تهنأة" : "IT'S " + this.state.name + " BIRTHDAY SAY SOMETHING !"}</p>
                                    </div>
                                    <Formik
                                        initialValues={{
                                            api_token: localStorage.getItem("token"),
                                            msg: '',
                                            id: this.state.id,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            msg: Yup.string()
                                                .required(<Translate content="msg_required"/>),


                                        })}
                                        onSubmit={values => {
                                            axios.post(window.$urlName + 'send-birthday-msg', values, {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Accept': 'application/json'
                                                }

                                            }).then((response) => {
                                                this.setState({
                                                    success: true,
                                                    disabled: false
                                                })
                                            }).catch(function (error) {
                                                console.log(error)
                                            })

                                        }}
                                    >

                                        {({errors, status, touched}) => (
                                            <Form>
                                                {this.state.success ?
                                                    <Translate className="successPrg" content="msgSend"
                                                               component="p"/> : null}
                                                <div className="form-group">
                                                    <Field name="msg" id="msg" type="text" component="textarea"
                                                           className={'form-control' + (errors.msg && touched.msg ? ' is-invalid' : '')}
                                                           placeholder={localStorage.getItem("lang") === "ar" ? "إكتب رسالتك" : "Write your message"}/>
                                                    <ErrorMessage name="msg" component="div"
                                                                  className="invalid-feedback"/>
                                                </div>

                                                <button type="submit" disabled={!this.state.disabled}><Translate
                                                    content="send"/></button>


                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </ModalBody>
                        </Modal>
                    </div>}
            </Fragment>
        )
    }
}

export default Home