export default {

    'lang': 'العربية',
    'welcome': 'Hi',
    'home': 'Home',
    'profile': 'Profile',
    'check-in-history': 'Check-in History',
    'my_team': 'My Team',
    'requests': 'Requests',
    'my_tasks': 'My Tasks',
    'public_vacations': 'Public Vacations',
    'meetings': 'Meetings',
    'help': 'Help',
    'check_in': 'Check In',
    'check_out': 'Check out',

    'company': 'Company',
    'hiring_date': 'Date of Hire',
    'birth_date': 'Date of Birth',
    'salary': 'Salary',
    'normal_vacation': 'Normal Vacations',
    'casual_vacation': 'Casual Vacations',
    'salary_sheet': 'Salary Sheet',
    'log_out': 'Logout',
    'edit': 'Edit',
    'full_salary': 'Full Salary',
    'work_day': 'W.days',
    'absent': 'Absent',
    'advance': 'Advance',
    'deduction': 'Deduction',
    'net_salary': 'Net Salary',
    'search': 'Search',
    'send_message': 'Send Message',
    'summary': 'Summary',
    'task': 'Task',
    'send_msg_for_team': 'Send Message for your team',
    'send': 'Send',
    'vacation_name': 'Vacation Name',
    'vacation_date': 'Vacation Date',
    'contact_us': 'Contact Us',
    'chat_us': 'Chat with us',
    'new_feature': 'New Feature',
    'complain': 'Complain',
    'your_comment_about': 'Your Comment About',
    'vacations': 'Vacations',
    'complains': 'Complains',
    'leaving': 'Leaves',
    'accept': 'Accept',
    'reject': 'Reject',
    'approved': 'Approved',
    'rejected': 'Rejected',
    'date': 'Date',
    'subject': 'Message',
    'state': 'State',
    'add_new_request': 'Add New Request',
    'vacation_type': 'Type',
    'choose_type': 'Choose Type',
    'date_from': 'From',
    'date_to': 'To',
    'request_details': 'Request details',
    'vacation_type_required': 'Vacation type is required',
    'date_required': 'Date is required',
    'details_required': 'Details is required',
    'back_log': 'BackLog',
    'in_progress': 'In Progress',
    'done': 'Done',
    'change_state': 'Change State',
    'progress': 'Progress',
    'projects_done': 'Projects done',
    'projects_inProgress': 'Projects in progress',
    'projects_backlog': 'Projects backlog',
    'history': 'History',
    'add_new_task': 'Add New Task',
    'title': 'Title',
    'project': 'Project',
    'dateLine': 'DeadLine',
    'colorCode': 'Color code',
    'title_required': 'Title is required',
    'project_required': 'Project is required',
    'dateLine_required': 'DeadLine is required',
    'color_required': 'Color code is required',
    'about_required': 'About task is required',
    'save_task': 'Save Task',
    'choose_color': 'Choose color',
    'addTaskTo': 'Add task to',
    'add_new_meeting': 'Add New Meeting',
    'time_from': 'Time from',
    'time_to': 'Time to',
    'invite_people': 'Invite people',
    'save_meeting': 'Save meeting',
    'time_required': 'Time is required',
    'description_required': 'Description is required',
    'from': 'From',
    'to': 'To',
    'upload': 'Upload',
    'phone': 'Phone',
    'name': 'Name',
    'email': 'Email',
    'password': 'Password',
    'save': 'Save',
    'back': 'Back',
    'name_validation': 'Name must be at least 4 character',
    'phone_validation': 'Phone not valid',
    'email_validation': 'Email not valid',
    'password_validation': 'Password must be at least 6 character',
    'what_you_feel': 'What You Fell!',
    'sad_reason': 'Your sad reason !',
    'no_employees': 'There are no employees in this department',
    'userError': 'Please check task members',
    'taskColorReq': 'Color Task is required',
    'no_tasks': 'There are no tasks',
    'all': 'All',
    'no_meetings': 'There are no meetings',
    'typeReq': 'Type is required',
    'msg_required': 'Message is required',
    'msgSend': 'Your message has been send ',
    'type': 'Type',
    'no_requests': 'There are no requests',
    'time': 'Time',
    'late': 'Late',
    'no_members': 'There are no members in your team',
    'currentProgress': 'Current progress',
    'see_sessions': 'See Sessions',
    'no_sheets': 'There are no salary sheets',
    'say_something': 'Say something',
    'no_notifications': 'There are no notifications',
    'welcome_aboard': 'Welcome Aboard',
    'youHave': 'You have',
    'meeting': 'meeting',
    'department': 'Department',
    'areYouSure': 'Are you sure to',
    'thisRequest': ' this request?',
    'hours': 'Hours',
    'typeYourMsg': 'Type your message',
    'pleaseTypeYourMsg': 'Please Type your message',
    'connectionLost': 'Internet connection is lost',
    'sessionPending': 'Your session is pending approval',

    'locationAccess':'Please access location permission',

    'copy_right': 'All rights reserved. Copyright © 2020 Kites Keeper.',

    //sign-in
    'hello':'Hello',
    'sign_to_account':'SIGN IN TO YOUR ACCOUNT',
    'invalidEmail':'Invalid email',
    'emailRequired':'Your email is required',
    'password_6':'Your password must be at least 6 character',
    'passwordRequired':'Your password is required',
    'forgetPassword':'Forget Password ?',
    'login':'Login',
    'invalidEmailPassword':'Invalid Email or Password',
    'reset':'Reset',
};