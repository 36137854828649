import React, {Component, Fragment} from 'react';
import axios from "axios";
import Translate from "react-translate-component";
import plus from "../component/assest/img/pluse.png"
import {Modal, ModalBody} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import toggle from "../component/assest/img/toggle.svg";
import approve from "../component/assest/img/approve.svg";
import reject from "../component/assest/img/reject.svg";
import LoadingSpinner from "./loading-spinner";

class MemberRequests extends Component {
    constructor(props) {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const date = yyyy + '-' + mm + '-' + dd;

        super(props);
        this.state = {
            memberData : this.props.location.state.memberData,
            requestModel: false,
            approvalModel:false,
            approveRequestId:'',
            approveRequestState:'',
            types: [],
            selectedTypeId: 1,
            typeRequests: [],
            loading: true,
            sendRequestTypeId: 1,
            dateFrom: false,
            dateTo: false,
            timeFrom: false,
            timeTo: false,
            msg: false,
            minDate:date,
        }
    }

    componentDidMount() {
        let values = {
            api_token: this.state.memberData.api_token,
        };
        axios.post(window.$urlName + 'request-types', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({
                types: response.data.types,
                loading: false,
            })
            this.getTypeRequests();
        }).catch(function (error) {
            if (error.response.status === 401) {
                window.location.pathname = "/"
            }
            console.log(error)
        })
    }

    sendRequestModel = () => {
        this.setState(prevState => ({
            requestModel: !prevState.requestModel,
        }));
    };

    getType = (e) => {
        let typeId =  e.target.value;
        let data = {
            api_token: this.state.memberData.api_token,
            typeId: typeId
        };
        if (typeId !== "") {
            axios.post(window.$urlName + 'check-type', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then((response) =>{
                this.setState({
                    dateFrom: response.data.data.dateFrom,
                    dateTo: response.data.data.dateTo,
                    timeFrom: response.data.data.timeFrom,
                    timeTo: response.data.data.timeTo,
                    msg: response.data.data.msg,
                    sendRequestTypeId: typeId,
                    loading: false,
                })
            }).catch(function (error) {
                if (error.response.status === 401) {
                    window.location.pathname = "/"
                }
                console.log(error)
            })
        } else {
            this.setState({
                dateFrom: false,
                dateTo: false,
                timeFrom: false,
                timeTo: false,
                msg: false,
                loading: false,
            })
        }
    }

    getTypeRequests = () => {
        let typeId = document.getElementById("types").value;

        let data = {
            api_token: this.state.memberData.api_token,
            typeId: typeId
        };
        axios.post(window.$urlName + 'get-request-by-type', data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then((response) => {
            let requests = response.data.requests.length ? (
                    response.data.requests.map((typeRequest, index) => {
                        return (
                            <div className="col-lg-6 col-md-6" key={index}>
                                <div className="myRequestCard">
                                    {typeRequest.dateFrom ?
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <Translate content="date" component="h3"/>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <h4 className="dateInfo">
                                                {typeRequest.dateFrom ? typeRequest.dateFrom :null}
                                                {typeRequest.dateTo ? <Translate content="to" component="span"/> :null}
                                                {typeRequest.dateTo ? typeRequest.dateTo :null}
                                            </h4>
                                        </div>
                                    </div>
                                        :null}
                                    {typeRequest.dateFrom ? <hr/> : null}

                                    {typeRequest.timeFrom ?
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <Translate content="time" component="h3"/>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <h4 className="dateInfo">
                                                    {typeRequest.timeFrom ? typeRequest.timeFrom :null}
                                                    {typeRequest.timeTo ? <Translate content="to" component="span"/> :null}
                                                    {typeRequest.timeTo ? typeRequest.timeTo :null}
                                                </h4>
                                            </div>
                                        </div>
                                        :null}
                                    {typeRequest.timeFrom ? <hr/> : null}

                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <Translate content="subject" component="h3"/>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <h4>{typeRequest.msg}</h4>
                                        </div>
                                    </div>

                                    {typeRequest.state === "approved"?
                                        <div className="approvalBtns">
                                            <button className="accepted"><Translate content="approved"/></button>
                                        </div>
                                        :null}

                                    {typeRequest.state === "rejected"?
                                        <div className="approvalBtns">
                                            <button className="rejected"><Translate content="rejected"/></button>
                                        </div>
                                        :null}

                                    {typeRequest.state === ""?
                                        <div className="approvalBtns">
                                            <button type="button" className="accept" onClick={() => this.approve(typeRequest.id, "approved")}><img src={approve} alt="toggle"/></button>
                                            <button type="button" className="reject" onClick={() => this.approve(typeRequest.id, "rejected")}><img src={reject} alt="toggle"/></button>
                                        </div>
                                        :null}
                                </div>
                            </div>
                        )
                    })
                ) :
                (
                    <Translate content="no_requests" component="p" className="emptyObject"/>
                );
            this.setState({
                typeRequests: requests,
                selectedTypeId: typeId,
                loading: false,
            })
        }).catch(function (error) {
            if (error.response.status === 401) {
                window.location.pathname = "/"
            }
            console.log(error)
        })
    }

    approve = (requestId, state) => {
        this.setState({
            approveRequestId:requestId,
            approveRequestState:state,
        });

        this.approveModal();
    }

    approveModal = () =>{
        this.setState(prevState => ({
            approvalModel: !prevState.approvalModel,
        }));
    }

    render() {
        let types = this.state.types.length ? (
                this.state.types.map((type, index) => {
                    return (
                        <option key={index}
                                value={type.id}>{localStorage.getItem("lang") === "ar" ? type.NameAr : type.nameEn}</option>
                    )
                })
            ) :
            (
                <option value=""><Translate content="choose_type"/></option>
            );
        return (
            <Fragment>
                {this.state.loading ? <div className="loadingMargin">
                        <div className="parent">
                            <div className="child">
                                <LoadingSpinner color={localStorage.getItem("color")} height={'5%'} width={'5%'}/></div>
                        </div>
                    </div> :
                    <div className="container">
                        <div className="tabsBody">
                            <div className="tabList">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="personalIntro">
                                            <div className="profileSideImg" style={{
                                                backgroundImage: "url(" + this.state.memberData.photo + ")",
                                            }}></div>

                                            <div className="personalIntroInfo">
                                                <h3>{this.state.memberData.name}</h3>
                                                <p>{this.state.memberData.position}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="personalIntro" style={{marginTop: "55px"}}>
                                            <select className="form-control typesSelect" id="types" onChange={this.getTypeRequests}>
                                                {types}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="personalIntro" style={{marginTop: "55px"}}>
                                            <button type="button" onClick={this.sendRequestModel}
                                                    className="addRequestButton"><Translate
                                                content="add_new_request"/><img src={plus} alt="add"/></button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                {this.state.typeRequests}
                            </div>
                        </div>
                    </div>
                }

                <Modal isOpen={this.state.requestModel} toggle={this.sendRequestModel} className="modalBody">
                    <ModalBody>
                        <div className="requestModalSec">
                            <Formik
                                initialValues={{
                                    api_token: this.state.memberData.api_token,
                                    typeId: this.state.sendRequestTypeId,
                                    dateFrom: '',
                                    dateTo: '',
                                    timeFrom: '',
                                    timeTo: '',
                                    msg: '',
                                }}
                                validationSchema={Yup.object().shape({
                                    // typeId: Yup.string()
                                    //     .required(<Translate content="vacation_type_required"/>),
                                    //
                                    // dateFrom: Yup.date()
                                    //     .required(<Translate content="date_required"/>),
                                    //
                                    // dateTo: Yup.date()
                                    //     .required(<Translate content="date_required"/>),
                                    //
                                    // time_from: Yup.string()
                                    //     .required(<Translate content="time_required"/>),
                                    //
                                    // time_to: Yup.string()
                                    //     .required(<Translate content="time_required"/>),
                                    //
                                    // date: Yup.date()
                                    //     .required(<Translate content="date_required"/>),
                                    //
                                    // msg: Yup.string()
                                    //     .required(<Translate content="details_required"/>),

                                })}
                                onSubmit={values => {
                                    const today = new Date();
                                    const dd = String(today.getDate()).padStart(2, '0');
                                    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                                    const yyyy = today.getFullYear();

                                    let data = {
                                        api_token: this.state.memberData.api_token,
                                        typeId: this.state.sendRequestTypeId,
                                        dateFrom: values.dateFrom,
                                        dateTo: values.dateTo,
                                        timeFrom: values.timeFrom,
                                        timeTo: values.timeTo,
                                        msg: values.msg,
                                    };

                                    if(values.dateTo !=='' && (Date.parse(values.dateFrom) > Date.parse(values.dateTo))) {
                                        alert('Date to must be greater than date from');
                                    }else if(values.timeTo !=='' && (Date.parse(yyyy+'-'+mm+'-'+dd+' '+values.timeFrom) >= Date.parse(yyyy+'-'+mm+'-'+dd+' '+values.timeTo))){
                                        alert('Time to must be greater than time from');
                                    }else{
                                        axios.post(window.$urlName + 'make-request', data, {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Accept': 'application/json'
                                            }
                                        }).then((response) => {
                                            if(response.data.message === "success"){
                                                window.location.reload();
                                            }
                                        }).catch(function (error) {
                                            console.log(error)
                                        })
                                    }
                                }}
                            >
                                {({errors, status, touched}) => (
                                    <Form>
                                        <div className="form-group">
                                            <label htmlFor="requestType"><Translate content="vacation_type"/></label>
                                            <select
                                                className={'form-control' + (errors.typeId && touched.typeId ? ' is-invalid' : '')}
                                                name="typeId" id="requestType" onChange={this.getType} required>
                                                <Translate content="choose_type" component="option" value=""/>
                                                {types}
                                            </select>
                                            <ErrorMessage name="type" component="div" className="invalid-feedback"/>
                                        </div>
                                        <div className="row">
                                            {this.state.dateFrom ?
                                                <div className="col-lg-5 col-md-5">
                                                    <label htmlFor="dateFrom"><Translate content="date_from"/></label>
                                                    <div className="form-group">
                                                        <Field name="dateFrom" id="dateFrom" type="date" required="required" min={this.state.minDate}
                                                               className={'form-control' + (errors.dateFrom && touched.dateFrom ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="dateFrom" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                </div> : null}

                                            {this.state.dateTo ?
                                                <div className="col-lg-2 col-md-2">
                                                    <img src={toggle} alt="toggle"/>
                                                </div>
                                                : null}
                                            {this.state.dateTo ?
                                                <div className="col-lg-5 col-md-5">
                                                    <label htmlFor="dateTo"><Translate content="date_to"/></label>
                                                    <div className="form-group">
                                                        <Field name="dateTo" id="dateTo" type="date"  required="required"
                                                               className={'form-control' + (errors.dateTo && touched.dateTo ? ' is-invalid' : '')}/>
                                                        <ErrorMessage name="dateTo" component="div"
                                                                      className="invalid-feedback"/>
                                                    </div>
                                                </div> : null}
                                        </div>


                                        <div>
                                            <div className="row">
                                                {this.state.timeFrom ?

                                                    <div className="col-lg-5 col-md-5">
                                                        <label htmlFor="timeFrom"><Translate
                                                            content="time_from"/></label>
                                                        <div className="form-group">
                                                            <Field name="timeFrom" id="timeFrom" type="time" required="required"
                                                                   className={'form-control' + (errors.timeFrom && touched.timeFrom ? ' is-invalid' : '')}/>
                                                            <ErrorMessage name="timeFrom" component="div"
                                                                          className="invalid-feedback"/>
                                                        </div>
                                                    </div> : null}
                                                {this.state.timeTo ?
                                                    <div className="col-lg-2 col-md-2">
                                                        <img src={toggle} alt="toggle"/>
                                                    </div>
                                                    : null}
                                                {this.state.timeTo ?

                                                    <div className="col-lg-5 col-md-5">
                                                        <label htmlFor="timeTo"><Translate content="time_to"/></label>
                                                        <div className="form-group">
                                                            <Field name="timeTo" id="timeTo" type="time" required="required"
                                                                   className={'form-control' + (errors.timeTo && touched.timeTo ? ' is-invalid' : '')}/>
                                                            <ErrorMessage name="timeTo" component="div"
                                                                          className="invalid-feedback"/>
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        </div>
                                        {this.state.msg ?

                                            <div className="form-group">
                                                <label htmlFor="msg"><Translate content="request_details"/></label>

                                                <Field name="msg" id="msg" type="text" component="textarea" required="required"
                                                       className={'form-control' + (errors.msg && touched.msg ? ' is-invalid' : '')}>

                                                </Field>
                                                <ErrorMessage name="msg" component="div" className="invalid-feedback"/>


                                            </div>
                                            : null}

                                        <button type="submit"><Translate content="send"/></button>


                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.approvalModel} toggle={this.approveModal} className="modalBody">
                    <ModalBody>
                        <div className="requestModalSec">
                            <Formik
                                initialValues={{
                                    api_token: localStorage.getItem("token"),
                                    requestId: '',
                                    state: '',
                                }}
                                onSubmit={values => {
                                    let data = {
                                        api_token: localStorage.getItem("token"),
                                        requestId: this.state.approveRequestId,
                                        state: this.state.approveRequestState,
                                    };
                                    axios.post(window.$urlName + 'approve-request', data, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Accept': 'application/json'
                                        }
                                    }).then((response) => {
                                        if(response.data.message === "success"){
                                            window.location.reload();
                                        }
                                    }).catch(function (error) {
                                        console.log(error)
                                    })

                                }}
                            >
                                {({errors, status, touched}) => (
                                    <Form>
                                        <div className="form-group">
                                            <p><Translate content="areYouSure"/> <Translate content={this.state.approveRequestState==='approved'?"accept":"reject"}/> <Translate content="thisRequest"/> </p>
                                            <ErrorMessage name="type" component="div" className="invalid-feedback"/>
                                        </div>

                                        <button type="submit"><Translate content="send"/></button>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </ModalBody>
                </Modal>

            </Fragment>
        )
    }
}

export default MemberRequests