import React, {Component, Fragment} from 'react';
import {NavLink, Link} from 'react-router-dom';
import counterpart from "counterpart";
import Translate from "react-translate-component";
import App from "../App";

let ReactDOM = require('react-dom');

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userInfo: {},
            photo: '',
        };

    }
    // componentDidMount(){
    //     axios.post(window.$urlName + 'checkToken', {api_token: localStorage.getItem("token")}, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json'
    //         }
    //
    //     }).then((response) => {
    //
    //     }).catch(function(error){
    //         window.location.pathname = "/"
    //     })
    // }



    onLangChange = (e) => {
        counterpart.setLocale(e.target.value);
        if (counterpart.setLocale(e.target.value) === 'en') {
            localStorage.setItem("lang", "en");
            window.location.reload();
            window.onload = function () {
                ReactDOM.render(<App/>, document);
            };

        } else if (counterpart.setLocale(e.target.value) === 'ar') {
            localStorage.setItem("lang", "ar");
            window.location.reload();
            window.onload = function () {
                ReactDOM.render(<App/>, document);
            };

        }
    };

    render() {

        return (
            <Fragment>
                <div className="sideBar">

                        <div>
                            <div className="personalIntro">
                                <div className="profileSideImg" style={{
                                    backgroundImage: "url(" + localStorage.getItem("photo") + ")",
                                }}></div>

                                <div className="personalIntroInfo">
                                    <h3>{localStorage.getItem("name")}</h3>
                                    <p>{localStorage.getItem("position")}</p>
                                </div>
                            </div>

                            <div className="wlcIntro">
                                <h4><Translate content="welcome"/>, {localStorage.getItem("name")}!</h4>
                                <Translate content="welcome_aboard" component="p"/>
                            </div>

                        </div>
                    <div className="chatIcon">
                        <Link to="/chat"><i className="fa fa-commenting"></i></Link>
                    </div>
                    <div className="sideLinks">
                        <div className="NavLink"><NavLink activeClassName='is-active' to="/dashboard"><Translate
                            content="home"/></NavLink></div>
                        <div className="NavLink"><NavLink activeClassName='is-active' to="/profile"><Translate
                            content="profile"/></NavLink></div>
                        <div className="NavLink"><NavLink activeClassName='is-active' to="/check-in-history"><Translate
                            content="check-in-history"/></NavLink></div>
                        {localStorage.getItem('hasTeam') ==='yes' ?
                            <div className="NavLink"><NavLink activeClassName='is-active' to="/my-team"><Translate
                                content="my_team"/></NavLink></div>
                            : null}
                        <div className="NavLink"><NavLink activeClassName='is-active' to="/my-requests"><Translate
                            content="requests"/></NavLink></div>
                        <div className="NavLink"><NavLink activeClassName='is-active' to="/my-meetings"><Translate
                            content="meetings"/></NavLink></div>
                        <div className="NavLink"><NavLink activeClassName='is-active' to="/my-tasks"><Translate
                            content="my_tasks"/></NavLink></div>
                        <div className="NavLink"><NavLink activeClassName='is-active' to="/public-vacations"><Translate
                            content="public_vacations"/></NavLink></div>
                        <div className="NavLink"><NavLink activeClassName='is-active' to="/help"><Translate
                            content="help"/></NavLink></div>


                        <select onChange={this.onLangChange}
                                value={localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"}
                                className="langButton">
                            <option value="en">EN</option>
                            <option value="ar">AR</option>
                        </select>
                    </div>

                </div>

            </Fragment>
        );
    }
}


export default Sidebar;