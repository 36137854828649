import React, {Component, Fragment} from 'react';
// import {Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Modal, ModalBody} from "reactstrap"
import notification from "../component/assest/img/notification.svg"
import Translate from "react-translate-component";
import axios from "axios";
import {Modal, ModalBody} from "reactstrap";

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropDownOpen: false,
            unseen: 0,
            notifications: [],
            NotificationTitle: "",
            NotificationMsg: "",
            notificationModel: false,
        }
    }

    componentDidMount() {
        const token = localStorage.getItem("token");
        if (token == null) {
            window.location.pathname = "/"
        }

        let values = {
            api_token: token
        };
        axios.post(window.$urlName + 'getNotificationsUpdated', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then((response) => {
            let notifications = response.data.notifications;
            let num = 0;
            for (let notification of notifications) {
                if (notification.state === "") {
                    num += 1
                }
                this.setState({
                    unseen: num
                })
            }
            this.setState({
                notifications: notifications
            })

        }).catch(function (error) {
            if (error.response.status === 401) {
                window.location.pathname = "/"
            }
            console.log(error)
        })
    }

    toggle = () => {
        this.setState(prevState => ({
            dropDownOpen: !prevState.dropDownOpen,

        }));

    };

    seeNotificationTypeModel = () => {
        this.setState(prevState => ({
            notificationModel: !prevState.notificationModel,
        }));
    };

    seeNotification = (id, title, msg) => {
        this.setState({
            NotificationTitle: title,
            NotificationMsg: msg,
        })
        this.seeNotificationTypeModel()
        let values = {
            api_token: localStorage.getItem("token"),
            msgId: id
        };
        axios.post(window.$urlName + 'seeMessage', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        }).then((response) => {
            console.log(this.index);
            console.log(response.data)

        }).catch(function (error) {
            console.log(error)
        })
    }

    render() {
        let notifications = this.state.notifications.length ? (
                this.state.notifications.map((notification, index) => {
                    return (
                        <div className="dropdownRow" key={index}
                             onClick={() => this.seeNotification(notification.id, notification.title, notification.msg)}>
                            {notification.state === "" ? <div className="dot"></div> :
                                <i className="checkedNotifi fa fa-check-circle"></i>} <p>{notification.title}</p>
                            <span>{notification.time}</span>
                        </div>
                    )
                })

            ) :

            (
                <Translate content="no_notifications" component="p" className="emptyObject"/>

            );
        return (
            <Fragment>
                <div className="container-fluid panelHead">
                    <div className="pageContent">
                        <div className="row">
                            <div className="col-lg-10 col-md-10">
                                <div className="logoHeader" style={{
                                    backgroundImage: "url(" + localStorage.getItem("logo") + ")",
                                }}></div>

                            </div>
                            <div className="col-lg-2 col-md-2">
                                <div className="parent">
                                    <div className="child">
                                        <div className="dropdownSec">
                                            <div className="toggleButton" onClick={this.toggle}>
                                                <img src={notification} alt="notification"
                                                     className="notificationIcon"/>
                                                <span className="unseen">{this.state.unseen}</span>
                                            </div>
                                            {this.state.dropDownOpen ?
                                                <div className="dropdownList">
                                                    {notifications}

                                                </div>
                                                : null}
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.notificationModel} toggle={this.seeNotificationTypeModel}
                       className="modalBody">
                    <ModalBody>
                        <div className="notificationMessage">
                            <h3>{this.state.NotificationTitle}</h3>
                            <p>{this.state.NotificationMsg}</p>
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}


export default Header;