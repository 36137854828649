import React, {Component, Fragment} from 'react';
import axios from "axios";
import Translate from "react-translate-component";
import feature from "../component/assest/img/feature.png"
import activeFeature from "../component/assest/img/activeFeature.png"
import activeCompain from "../component/assest/img/activeCompain.png"
import complain from "../component/assest/img/complain.png"
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

class Help extends Component {
    constructor(props) {
        super(props)
        this.state = {
            featureImg: feature,
            complainImg: complain,
            type: "",
            typeReq: false,
            success: false,
            disabled: true
        }
    }

    featureChecked = () => {
        this.setState({
            featureImg: activeFeature,
            complainImg: complain,
            type: "feature",
            typeReq: false,

        })
        document.getElementById("featurePrg").style.color = "#7AA1F0";
        document.getElementById("complainPrg").style.color = "#3E4046";
    }

    complainChecked = () => {
        this.setState({
            complainImg: activeCompain,
            featureImg: feature,
            type: "complaint",
            typeReq: false,
        })
        document.getElementById("featurePrg").style.color = "#3E4046";
        document.getElementById("complainPrg").style.color = "#7AA1F0";
    }

    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="helpBody">
                        <div className="commentSec">
                            <Translate content="your_comment_about" component="h3"/>
                            <div className="complainType">
                                {this.state.typeReq ?
                                    <Translate className="userError" content="typeReq" component="p"/> : null}
                                {this.state.success ?
                                    <Translate className="successPrg" content="msgSend" component="p"/> : null}
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <img src={this.state.featureImg} alt="feature" onClick={this.featureChecked}/>
                                        <Translate content="new_feature" component="p" id="featurePrg"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <img src={this.state.complainImg} alt="complain"
                                             onClick={this.complainChecked}/>
                                        <Translate content="complain" component="p" id="complainPrg"/>
                                    </div>
                                </div>
                            </div>
                            <Formik
                                initialValues={{
                                    api_token: localStorage.getItem("token"),
                                    msg: '',
                                    type: '',
                                }}
                                validationSchema={Yup.object().shape({
                                    msg: Yup.string()
                                        .required(<Translate content="msg_required"/>),

                                })}
                                onSubmit={values => {
                                    if (this.state.type !== "") {
                                        values.type = this.state.type
                                        axios.post(window.$urlName + 'suggest', values, {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Accept': 'application/json'
                                            }

                                        }).then((response) => {
                                            console.log(response.data)
                                            this.setState({
                                                success: true,
                                                disabled: false
                                            })
                                        }).catch(function (error) {
                                            console.log(error)
                                        })

                                    } else {
                                        this.setState({
                                            typeReq: true
                                        })
                                    }
                                }}
                            >

                                {({errors, status, touched}) => (
                                    <Form>
                                        <div className="form-group">
                                            <Field name="msg" id="msg" type="text" component="textarea"
                                                   className={'form-control' + (errors.msg && touched.msg ? ' is-invalid' : '')}
                                                   placeholder={localStorage.getItem("lang") === "ar" ? "إكتب رسالتك" : "Write your message"}/>
                                            <ErrorMessage name="msg" component="div" className="invalid-feedback"/>
                                            <button type="submit" disabled={!this.state.disabled}><Translate
                                                content="send"/></button>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="contactUsDiv">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <Translate content="chat_us" component="p"/>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <a href="https://wa.me/201152907995" target="_blank" rel='noopener noreferrer'><Translate content="contact_us"/></a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>


                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default Help